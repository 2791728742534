import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

export default withGoogleMap(class Map extends React.PureComponent {

  static propTypes = {
    marker: React.PropTypes.object,
    onMapLoad: React.PropTypes.func.isRequired,
    onMapClick: React.PropTypes.func
  };

  state = {
    initialPosition: {
      latitude: -39.5052721,
      longitude: 176.8767779
    }
  };

  lastDoubleClick = Date.now();
  lastClick = Date.now();

  componentDidMount() {
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      // Max age of 30 minutes
      maximumAge: 30 * 60 * 1000
    };
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({ initialPosition: position.coords });
      },
      () => (null),
      options
    );
  }

  componentWillUnmount() {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
  }

  onDblClick() {
    this.lastDoubleClick = Date.now();
  }

  onClick(...args) {
    if (!this.props.onMapClick) {
      return;
    }

    const thisLastClick = Date.now();

    this.lastClick = thisLastClick;

    setTimeout(() => {

      if (thisLastClick !== this.lastClick || Math.abs(this.lastClick - this.lastDoubleClick) < 300) {
        return;
      }

      this.props.onMapClick(...args);

    }, 250);
  }

  getPosition() {
    if (this.props.marker && this.props.marker.position) {
      const latLng = this.props.marker.position;
      return {
        lat: latLng.lat instanceof Function ? latLng.lat() : latLng.lat,
        lng: latLng.lng instanceof Function ? latLng.lng() : latLng.lng
      };
    }
    return {
      lat: this.state.initialPosition.latitude,
      lng: this.state.initialPosition.longitude
    };
  }

  onDrag() {
    this.lastDrag = Date.now();
    this.lastDragPosition = this.getPosition();
  }

  render() {
    let marker = '';

    if (this.props.marker) {
      marker = <Marker {...this.props.marker} />;
    }

    return (
      <GoogleMap
        ref={(map, ...args) => {
          this.props.onMapLoad(map, ...args);
          const pos = this.lastDragPosition;
          if (pos && this.lastDragPosition && this.lastDragPosition.lat === pos.lat && this.lastDragPosition.lng === pos.lng) {
            return;
          }
          if (this.lastDrag && (Date.now() - this.lastDrag) < 30000) {
            return;
          }
          if (!map) {
            return;
          }
          map.panTo(
            this.getPosition()
          );
        }}
        className="google-map"
        onDragStart={() => {}}
        onClick={this.onClick.bind(this)}
        onDblClick={this.onDblClick.bind(this)}
        defaultZoom={13}
        defaultCenter={this.getPosition()}
      >
        {marker}
      </GoogleMap>
    );
  }

});
