import { useMemo } from "react";
import Moment from "moment";
import ParseDuration from "parse-duration";

export function getTotalTime(visit, viewsEnabled) {
  function getMemoValues() {
    if (!visit.submission) {
      return [undefined, undefined, undefined];
    }
    const { start, end, travel_time } = visit.submission;
    const startValue = (start && start.$date) || start,
      endValue = (end && end.$date) || end;
    return [startValue, endValue, travel_time];
  }

  return useMemo(() => {
    if (!visit.submission) {
      return "";
    }

    const { start, end, travel_time } = visit.submission;

    const startMoment = Moment((start && start.$date) || start),
      endMoment = Moment((end && end.$date) || end);

    // Ensure it is the same day, they may have change this
    endMoment.year(startMoment.year());
    endMoment.month(startMoment.month());
    endMoment.date(startMoment.date());

    const differenceInHoursOnSite = Math.round(
      Math.floor(Math.abs(startMoment.diff(endMoment, "hours", true)) * 100) /
        100,
      2
    );

    let hoursTravelled = ParseDuration(
      typeof travel_time === "string" ? travel_time : ""
    );

    if (isNaN(+hoursTravelled)) {
      hoursTravelled = 0;
    } else {
      const hourInMilliseconds = 60 * 60 * 1000;

      // If it is under 10 seconds, assume they just wrote the number like they were asked to
      // no one is going to travel "10 seconds"
      if (hoursTravelled < 10000) {
        hoursTravelled = hoursTravelled * hourInMilliseconds;
      }

      hoursTravelled =
        Math.floor((hoursTravelled / hourInMilliseconds) * 100) / 100;
    }

    const total = `, ${differenceInHoursOnSite + hoursTravelled}h total`;

    return `${differenceInHoursOnSite}h on site, ${hoursTravelled}h travelling${
      viewsEnabled.hoursTotal ? total : ""
    }`;
  }, getMemoValues());
}
