import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {
  lightBlue,
  grey,
  pink
} from '@material-ui/core/colors';
import {
  lightBlack,
  white,
  fullBlack
} from 'material-ui/styles/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

const theme = {
  fontFamily: 'Helvetica Neue, HelveticaNeueLT-Roman, Open Sans, sans-serif',
  palette: {
    primary1Color: '#1db3e7',
    primary2Color: lightBlue['400'],
    primary3Color: grey['400'],
    accent1Color: pink['A200'],
    accent2Color: pink['A400'],
    accent3Color: pink['A700'],
    textColor: lightBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: lightBlue['400'],
    disabledColor: fade(lightBlack, 0.3),
    pickerHeaderColor: lightBlue,
    clockCircleColor: fade(lightBlack, 0.07),
    shadowColor: fullBlack
  },
  appBar: {
    color: grey['200']
  }
};

export {
  theme
};

export default getMuiTheme(theme);
