import Bugsnag from "@bugsnag/js";
import BugsnagReact from "@bugsnag/plugin-react";
import StaticConfig, { getEnvironment } from "./static-config";
import React from "react";
import Package from "../../package";

const bugsnagClient = Bugsnag({
  filters: [/access_token/, /id_token/],
  releaseStage: getEnvironment(),
  ...StaticConfig.bugsnag,
  appVersion: Package.version.Bugsnag,
  enabledReleaseStages: ["staging", "production"],
});
bugsnagClient.use(BugsnagReact, React);

export default bugsnagClient;
