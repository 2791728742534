import PropTypes from 'prop-types';
import React from 'react';
import DropZone from 'react-dropzone';
import Bytes from 'bytes';
import ClassNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import DialogBox from './dialog-box';
import { fetch } from '../services/fetch';

class DropZoneWrapper extends React.PureComponent {

  state = {
    dialogOpen : false,
    uploadingFiles: false,
   };

  onDragEnter() {
    return new Promise((resolve) => this.setState({
      draggingOverZone: true
    }, resolve));
  }

  onDragExit() {
    return new Promise((resolve) => this.setState({
      draggingOverZone: false
    }, resolve));
  }

   async onFileDropContinued(files, folder= ''){
    const filesLimit = this.props.fileLimit ? files.slice(0, this.props.fileLimit) : files;

    return filesLimit.reduce(async function uploadFiles(promise, file) {
      await promise;

      if (this.props.onFileBeforeUpload instanceof Function) {
        const base64Reader = await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = resolve.bind(null, reader);
          reader.onerror = reject;
          reader.onabort = reject;

          reader.readAsDataURL(file);
        });
        await this.props.onFileBeforeUpload(file, base64Reader.result);
      }

      await new Promise((resolve) => this.setState({
        uploadingFiles: true
      }, resolve));

      const formData = new FormData();

      formData.append('file', file);

      const response = await fetch('/upload', {
        method: 'POST',
        body: formData,

      });
      let details = await response.json();

      await new Promise((resolve) => this.setState({
        uploadingFiles: false
      }, resolve));
      details =  await  Object.assign({}, {...details}, {folder});

      return this.props.onFileDetails(details, file);
    }.bind(this), null);
  }

  async handleUpload(folder){
    await this.onFileDropContinued(this.state.files, folder);
    this.setState({
      files: null,
      dialogOpen: false
    });
  }

  async onFileDrop(files) {
    await this.onDragExit();
    this.setState({
      dialogOpen: true,
      files: files
    })
  }

  handleClose = () =>{
    this.setState({
      dialogOpen: false
    });
  };

  render() {
    const dropzoneWrapperClass = ClassNames('dropzone-wrapper flex-grid row', this.state.draggingOverZone ? 'dropzone-drag' : '', this.props.className || ''),
      dropzoneStyles = {
        width: '100%'
      },
      maxUpload = 'number' === typeof this.props.maxUpload && !isNaN(this.props.maxUpload) && this.props.maxUpload || Bytes('64mb');

    let controls = (
      <div
        className="dropzone-contents"
        style={dropzoneStyles}
        onDragEnter={this.onDragEnter.bind(this)}
        onDragLeave={this.onDragExit.bind(this)}
        onDragEnd={this.onDragExit.bind(this)}
        onDragExit={this.onDragExit.bind(this)}
      >
        <LinearProgress
          mode="indeterminate"
          className={this.state.uploadingFiles || this.props.showProgress ? 'uploading-files' : 'not-uploading-files'}
        />
        <div className="flex-grid flex-col-center">
          <h3><FormattedMessage id="app.dropZone.uploadMessage" /></h3>
          <span><FormattedMessage id="app.dropZone.uploadOrMessage" /></span>
          <Button
            variant="contained"
            className="select-file-button"
          >
            <FormattedMessage id="app.dropZone.uploadSelectMessage" />
          </Button>
          <span><FormattedMessage id="app.dropZone.maxUpload" values={{ bytes: Bytes(maxUpload) }} /></span>
        </div>
        {this.props.children}
      </div>
    );

    if (this.props.controls) {
      controls = this.props.controls;
    }
    const actions = [
      <Button
        variant="contained"
        style={{marginRight: '20px'}}
        default={true}
        onClick={this.handleClose}
      >
        Cancel
      </Button>
    ];

    return (
      <div className={dropzoneWrapperClass}>
        <DropZone
          onDrop={this.onFileDrop.bind(this)}
          className="flex flex-grid row center"
          maxSize={maxUpload}
          accept={this.props.accept}
        >
          {controls}
        </DropZone>
        <DialogBox
          showProgress={this.state.uploadingFiles || this.props.showProgress}
          handleUpload = {this.handleUpload.bind(this)}
          uploadingFiles = {this.state.uploadingFiles}
          handleClose = {this.handleClose}
          dialogOpen = {this.state.dialogOpen}
          actions = {actions}
        />
      </div>
    );
  }
}

DropZoneWrapper.propTypes = {
  onFileBeforeUpload: PropTypes.func,
  onFileDetails: PropTypes.func.isRequired,
  fileLimit: PropTypes.number,
  accept: PropTypes.string,
  maxUpload: PropTypes.number,
  children: PropTypes.any,
  controls: PropTypes.any,
  className: PropTypes.string,
  showProgress: PropTypes.bool
};

export default DropZoneWrapper;
