/* eslint-disable global-require */
import React from "react";
import { Route, Redirect, Switch } from "react-router";
import App from "./modules/app/app";
import { superLazyRender } from "./services/lazy";

const Dashboard = superLazyRender(() =>
  import("./modules/dashboard/pages/dashboard-page")
);
const VisitsPage = superLazyRender(() =>
  import("./modules/dashboard/pages/visits-page")
);
const JobsPage = superLazyRender(() =>
  import("./modules/dashboard/pages/jobs-page")
);
const CreateJobPage = superLazyRender(() =>
  import("./modules/job/pages/create-job-page")
);
const EditJobPage = superLazyRender(() =>
  import("./modules/job/pages/edit-job-page")
);
const ViewJobPage = superLazyRender(() =>
  import("./modules/job/pages/view-job-page")
);
const JobVisitPage = superLazyRender(() =>
  import("./modules/job/pages/submit-job-visit-page")
);
const Notifications = superLazyRender(() =>
  import("./modules/notification/pages/notifications-page")
);
const Users = superLazyRender(() => import("./modules/user/pages/users-page"));
const Settings = superLazyRender(() =>
  import("./modules/settings/pages/settings-page")
);
const Reports = superLazyRender(() =>
  import("./modules/reports/pages/reports-page")
);
const ChangeCountryScreen = superLazyRender(() =>
  import("./modules/app/change-country")
);

export default () => (
  <App>
    <Switch>
      <Route exact path="/dashboard" render={Dashboard} />
      <Route exact path="/dashboard/:year/:month/:date" render={Dashboard} />
      <Route exact path="/visits/:year/:month" render={VisitsPage} />
      <Route exact path="/visits/:year/:month/:date" render={VisitsPage} />
      <Route exact path="/visits" render={VisitsPage} />
      <Route exact path="/jobs" render={JobsPage} />
      <Route exact path="/jobs/:q" render={JobsPage} />
      <Route exact path="/job/create" render={CreateJobPage} />
      <Route exact path="/job/:id/edit" render={EditJobPage} />
      <Route exact path="/job/:id" render={ViewJobPage} />
      <Route exact path="/job/visit/:id" render={JobVisitPage} />
      <Route exact path="/notifications" render={Notifications} />
      <Route exact path="/users" render={Users} />
      <Route exact path="/settings" render={Settings} />
      <Route exact path="/reports" render={Reports} />
      <Route
        exact
        path="/config/changeCountry/:country_code"
        render={ChangeCountryScreen}
      />
      <Redirect to="/dashboard" />
    </Switch>
  </App>
);
