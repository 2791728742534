import React from 'react';
import Moment from 'moment';
import { injectUser } from '../../../services/user';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import ActionDeleteForever from 'material-ui/svg-icons/action/delete-forever';
import IconButton from 'material-ui/IconButton';
import { fetch } from '../../../services/fetch';

class Comments extends React.PureComponent {

  state = {
    savingComment: false,
    commentText: ''
  };

  getUserMap() {

    this.userMap = this.userMap || new WeakMap();

    if (this.userMap.has(this.props.users)) {
      return this.userMap.get(this.props.users);
    }

    const map = (this.props.users || [])
      .reduce((m, user) => Object.assign({}, m, {
        [user._id.$oid]: user
      }), {});

    this.userMap.set(this.props.users || false, map);

    return map;
  }

  getUsername(userReference) {
    if (typeof userReference === 'string') {
      return userReference;
    }
    if (!(userReference && userReference.$id)) {
      return 'Not Provided';
    }
    const map = this.getUserMap(),
      user = map[userReference.$id.$oid];
    return user && (user.name || user.username || user.email) || 'Not Provided';
  }

  onCommentTextChange(event) {
    if (this.props.onTextChange) {
      this.props.onTextChange(event.target.value)
    }
    return new Promise((resolve) => this.setState({
      commentText: event.target.value
    }, resolve));
  }

  async submitOnEnter(event) {
    if (event.keyCode !== 13) {
      return null;
    }
    return this.submitComment();
  }

  async deleteComment(comment) {
    if (!(
        this.props.roles.isOrChild(this.props.user.role, 'country_admin') ||
        comment.user.$id.$oid === this.props.user._id.$oid
      )) {
      return null;
    }

    await new Promise((resolve) => this.setState({
      savingComment: true
    }, resolve));

    await fetch('/job/deleteComment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json+bson'
      },
      body: JSON.stringify({
        _id: this.props.job._id,
        commentId: comment._id,
        visit_id: this.props.visit ? this.props.visit._id : undefined
      }),
      credentials: 'include'
    });

    const comments = this.props.comments.slice(),
      index = comments.indexOf(comment);

    if (index > -1) {
      comments.splice(index, 1);
    }

    const updatePromise = this.props.onComments(comments);

    const promise = new Promise((resolve) => this.setState({
      savingComment: false
    }, resolve));

    await updatePromise;
    await promise;
  }

  async submitComment() {
    await new Promise((resolve) => this.setState({
      savingComment: true
    }, resolve));

    const response = await fetch('/job/createComment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json+bson'
      },
      body: JSON.stringify({
        _id: this.props.job._id,
        visit_id: this.props.visit && this.props.visit._id,
        commentText: this.state.commentText
      }),
      credentials: 'include'
    });

    const comment = await response.json();

    const comments = this.props.comments.slice();

    comments.unshift(comment);

    const updatePromise = this.props.onComments(comments);

    if (this.props.onTextChange) {
      this.props.onTextChange("");
    }

    const promise = new Promise((resolve) => this.setState({
      savingComment: false,
      commentText: ''
    }, resolve));

    await updatePromise;
    await promise;
  }

  getText(text) {
    return text
      .split('\n')
      .map(
        (value, index, array) => (
          <React.Fragment key={index}>
            {value}
            {index + 1 < array.length ? <br /> : null}
          </React.Fragment>
        )
      );
  }

  render() {
    const isAdmin = this.props.roles.isOrChild(this.props.user.role, 'country_admin')

    return (
      <div className="comment-section flex-grid">
        <h3>Comments</h3>
        <div className="flex-grid row article-comment-submit">
          <TextField
            className="flex"
            hintText="Write a comment..."
            name="comment_text"
            inputStyle={{ margin: 0 }}
            value={this.state.commentText}
            onChange={this.onCommentTextChange.bind(this)}
            disabled={this.state.savingComment}
            onKeyDown={this.submitOnEnter.bind(this)}
          />
          <div>
            <RaisedButton
              className="submit-button"
              disabled={this.state.savingComment || !(this.state.commentText.trim().length)}
              onClick={this.submitComment.bind(this)}
            >
              <span style={{ padding: "0 5px" }}>
                Save Comment
              </span>
            </RaisedButton>
          </div>
        </div>
        <div className="flex-grid comment-list">
          {(this.props.comments || [])
            .concat(
              this.props.initialComment ? [
                {
                  text: this.props.initialComment,
                  user: "Handover",
                  readOnly: true
                }
              ] : []
            )
            .map((comment) => (
              <div className="comment flex-grid" key={`comment list ${comment._id && comment._id.$oid}`}>
                <div className="comment-content flex-grid row">
                  <div className="comment-content-text flex">
                    <span className="actual-text">{this.getText(comment.text)}</span>
                    <div className="comment-title flex-grid row">
                      <h5 className="comment-user">
                        {this.getUsername(comment.user)}
                      </h5>
                      {
                        (comment.date && comment.date.$date) ? (
                          <span className="flex">
                          &nbsp;
                            <FormattedMessage
                              id="app.job.commentDate"
                              values={{ date: Moment(comment.date.$date).format(
                                  this.props.intl.formatMessage({ id: 'app.job.commentDateFormat' })
                                ) }}
                            />
                        </span>
                        ) : null
                      }
                    </div>
                  </div>
                  {!comment.readOnly && (isAdmin || comment.user.$id.$oid === this.props.user._id.$oid) ? (
                    <div className="comment-controls flex-grid">
                      <IconButton onClick={this.deleteComment.bind(this, comment)}>
                        <ActionDeleteForever />
                      </IconButton>
                    </div>
                  ) : ''}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

}

Comments.propTypes = {
  initialComment: PropTypes.string,
  intl: intlShape,
  users: PropTypes.arrayOf(
    PropTypes.shape(
      {
        _id: PropTypes.shape({
          $oid: PropTypes.string.isRequired
        }).isRequired,
        username: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string
      }
    )
  ),
  comments: PropTypes.arrayOf(
    PropTypes.shape(
      {
        _id: PropTypes.shape({
          $oid: PropTypes.string.isRequired
        }).isRequired,
        user: PropTypes.shape({
          $id: PropTypes.shape({
            $oid: PropTypes.string.isRequired
          }).isRequired
        }).isRequired,
        text: PropTypes.string.isRequired,
        date: PropTypes.shape({
          $date: PropTypes.string.isRequired
        }),
        readOnly: PropTypes.bool
      }
    ).isRequired
  ),
  onComments: PropTypes.func.isRequired,
  job: PropTypes.shape(
    {
      _id: PropTypes.shape({
        $oid: PropTypes.string.isRequired
      }).isRequired
    }
  ),
  visit: PropTypes.shape(
    {
      _id: PropTypes.shape({
        $oid: PropTypes.string.isRequired
      }).isRequired
    }
  ),
  onTextChange: PropTypes.func
};

export default injectUser(injectIntl(Comments));
