import React, {
  memo,
  useCallback,
  useState,
  createRef,
  useMemo,
  useReducer,
} from "react";
import Stateless from "./stateless";
import { fetch } from "../../../../services/fetch";
import DatePicker from "material-ui/DatePicker";
import TimePicker from "material-ui/TimePicker";
import Moment from "moment";
import EditInvoiceDialog from "../edit-invoice-dialog";
import { useRole } from "../../../../services/user";
import EditAreaDialog from "../edit-area-dialog";

const dialogContainerStyle = {
  background: "rgba(0, 0, 0, 0.3)",
};

const styleDisplayNone = { display: "none" };

function getMoment(visit) {
  const momentString = visit.date && visit.date.$date,
    momentObject = momentString ? undefined : visit.date;
  return useMemo(() => {
    return Moment(
      momentString ? new Date(momentString) : momentObject || new Date()
    );
  }, [momentObject, momentString]);
}

export default memo(
  ({
    isDashboard,
    visit,
    job,
    onScheduleVisit,
    onNewVisit,
    onAssignVisit,
    applicators,
    onDeleteVisit: onDeleteVisitBase,
    onApproveSchedule: onApproveScheduleBase,
    onApprovedStatus: onApprovedStatusBase,
    onCompleteStatus: onCompleteStatusBase,
    onVisitUpdate: onVisitUpdateBase,
    visits,
    showScheduled,
    isViewJob,
    sidebar,
    onUpdateSingleVisit,
    onFilterJob,
    bluebeamFiles,
  }) => {
    const isAdmin = useRole("country_admin", "isOrChild"),
      isSales = useRole("sales", "is"),
      isAccounts = useRole("accounts", "is");

    const moment = getMoment(visit);
    const momentDate = useMemo(() => moment.toDate(), [moment]);

    const [isAddingComment, setIsAddingComment] = useState(false);
    const [isAddingStaticComment, setIsAddingStaticComment] = useState(false);

    const toggleAddingComments = useCallback(
      (event) => {
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
        // Let the toggle happen
        setIsAddingComment(!isAddingComment || isAddingStaticComment);
        setIsAddingStaticComment(false);
      },
      [setIsAddingComment, isAddingComment, setIsAddingStaticComment]
    );

    const toggleAddingStaticComments = useCallback(
      (event) => {
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
        // Let the toggle happen
        setIsAddingComment(false);
        setIsAddingStaticComment(isAddingComment || !isAddingStaticComment);
      },
      [
        setIsAddingComment,
        isAddingComment,
        isAddingStaticComment,
        setIsAddingStaticComment,
      ]
    );

    const onUpdateComments = useMemo(
      () =>
        onUpdateSingleVisit
          ? () => {
              onUpdateSingleVisit(visit);
              setIsAddingComment(false);
              setIsAddingStaticComment(false);
            }
          : undefined,
      [onUpdateSingleVisit, setIsAddingComment, setIsAddingStaticComment]
    );

    const onVisitUpdate = useMemo(
      () =>
        onVisitUpdateBase &&
        ((...args) => onVisitUpdateBase(visit, visit, job, ...args)),
      [onVisitUpdateBase, visit, job]
    );

    const [isEditingArea, setEditingAreaBase] = useState(false);
    const setEditingArea = useMemo(
      () => (onVisitUpdate ? (value) => setEditingAreaBase(value) : undefined),
      [setEditingAreaBase, onVisitUpdate]
    );
    const requestEditArea = useMemo(
      () =>
        setEditingArea
          ? (event) => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              setEditingArea(true);
            }
          : undefined,
      [setEditingArea]
    );

    const [isEditVisitInvoice, setEditVisitInvoiceBase] = useState(false);
    const setEditVisitInvoice = useMemo(
      () =>
        onVisitUpdate ? (value) => setEditVisitInvoiceBase(value) : undefined,
      [setEditVisitInvoiceBase, onVisitUpdate]
    );
    const requestEditVisitInvoice = useMemo(
      () =>
        setEditVisitInvoice
          ? (event) => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              setEditVisitInvoice(true);
            }
          : undefined,
      [setEditVisitInvoice, setEditVisitInvoice]
    );

    const [mutatedVisit, setMutatedVisitBase] = useState(undefined);
    const setMutatedVisit = useMemo(
      () => (value) => setMutatedVisitBase(value),
      [setMutatedVisitBase]
    );

    const onDeleteVisit = useMemo(
      () =>
        onDeleteVisitBase &&
        ((...args) => onDeleteVisitBase({ visit, job }, ...args)),
      [onDeleteVisitBase, visit, job]
    );
    const onApproveSchedule = useMemo(
      () =>
        onApproveScheduleBase &&
        ((...args) => onApproveScheduleBase({ visit }, ...args)),
      [onApproveScheduleBase, visit]
    );
    const onApprovedStatus = useMemo(
      () =>
        onApprovedStatusBase &&
        ((...args) => onApprovedStatusBase(visit, job, ...args)),
      [onApprovedStatusBase, visit, job]
    );
    const onCompleteStatus = useMemo(
      () =>
        onCompleteStatusBase &&
        ((...args) => onCompleteStatusBase(visit, job, ...args)),
      [onCompleteStatusBase, visit, job]
    );

    const datePickerRef = createRef(),
      timePickerRef = createRef();

    const onPickDate = useMemo(() => {
      if (!onScheduleVisit) {
        return undefined;
      }
      return function onPickDate(event) {
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
        if (!datePickerRef.current) {
          return;
        }
        datePickerRef.current.focus();
      };
    }, [datePickerRef]);

    const onPickTime = useMemo(() => {
      if (!onScheduleVisit) {
        return undefined;
      }
      return function onPickTime(event) {
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
        if (!timePickerRef.current) {
          return;
        }
        timePickerRef.current.focus();
      };
    }, [timePickerRef]);

    const onDatePicked = useMemo(() => {
      return function onDatePicked(unused, value) {
        onScheduleVisit(
          {
            year: value.getFullYear(),
            month: value.getMonth(),
            date: value.getDate(),
          },
          {
            visit,
            job,
          }
        );
      };
    }, [visit, job]);

    const onTimePicked = useMemo(() => {
      return function onTimePicked(unused, value) {
        onScheduleVisit(
          {
            hour: value.getHours(),
            minute: value.getMinutes(),
          },
          {
            visit,
            job,
          }
        );
      };
    }, [visit, job]);

    const duplicateVisit = useMemo(() => {
      return async function duplicateVisit() {
        const response = await fetch("/visit/duplicate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json+bson",
          },
          body: JSON.stringify({
            visit: {
              _id: visit._id,
            },
          }),
          credentials: "include",
        });

        const newVisit = await response.json();

        return onNewVisit({
          visit: newVisit,
          job,
        });
      };
    }, [job, visit._id]);

    const onRequestEditClose = useMemo(() => {
      return () => {
        setEditVisitInvoice(false);
        setEditingArea(false);
        setMutatedVisit(undefined);
      };
    }, [setEditVisitInvoice, setMutatedVisit]);

    const onEditVisitInvoiceSave = useMemo(() => {
      return async function onEditVisitInvoiceSave() {
        // Nothing changed
        if (!mutatedVisit || !onVisitUpdate) {
          return onRequestEditClose();
        }
        const invoice = mutatedVisit.invoice || {};
        await onVisitUpdate(mutatedVisit, job, {
          invoice,
          status: invoice.number ? "Complete" : mutatedVisit.status,
        });
        return onRequestEditClose();
      };
    }, [onRequestEditClose, onVisitUpdate, mutatedVisit, job]);

    const onEditVisitAreaSave = useMemo(() => {
      return async function onEditVisitAreaSave() {
        if (!mutatedVisit || !onVisitUpdate) {
          return onRequestEditClose();
        }
        onVisitUpdate(mutatedVisit, job, {
          area: mutatedVisit.area || [],
        });
        return onRequestEditClose();
      };
    }, [onRequestEditClose, mutatedVisit, job]);

    return (
      <Stateless
        onFilterJob={onFilterJob}
        isDashboard={isDashboard}
        isAdmin={isAdmin}
        isSales={isSales}
        isAccounts={isAccounts}
        moment={moment}
        visit={visit}
        job={job}
        onScheduleVisit={onScheduleVisit}
        onNewVisit={onNewVisit}
        onAssignVisit={onAssignVisit}
        applicators={applicators}
        onDeleteVisit={onDeleteVisit}
        onApproveSchedule={onApproveSchedule}
        onApprovedStatus={onApprovedStatus}
        onCompleteStatus={onCompleteStatus}
        onVisitUpdate={onVisitUpdate}
        visits={visits}
        showScheduled={showScheduled}
        isViewJob={isViewJob}
        sidebar={sidebar}
        duplicateVisit={duplicateVisit}
        addComment={toggleAddingComments}
        addStaticComment={toggleAddingStaticComments}
        onEditVisitArea={requestEditArea}
        onPickDate={onPickDate}
        onPickTime={onPickTime}
        onEditVisitInvoice={requestEditVisitInvoice}
        isAddingComment={isAddingComment}
        isAddingStaticComment={isAddingStaticComment}
        onUpdateComments={onUpdateComments}
        bluebeamFiles={bluebeamFiles}
      >
        <DatePicker
          style={styleDisplayNone}
          ref={datePickerRef}
          key="date-picker"
          name="date-picker"
          value={momentDate}
          onChange={onDatePicked}
          autoOk
          dialogContainerStyle={dialogContainerStyle}
        />
        <TimePicker
          value={momentDate}
          onChange={onTimePicked}
          autoOk
          style={styleDisplayNone}
          ref={timePickerRef}
          key="time-picker"
          name="time-picker"
          dialogBodyStyle={dialogContainerStyle}
        />
        {(isAdmin || isAccounts) && onVisitUpdate ? (
          <EditInvoiceDialog
            open={isEditVisitInvoice}
            onChange={setMutatedVisit}
            onRequestClose={onRequestEditClose}
            onSave={onEditVisitInvoiceSave}
            visit={mutatedVisit || visit}
            job={job}
            visits={visits}
          />
        ) : undefined}
        {isAdmin && onVisitUpdate ? (
          <EditAreaDialog
            open={isEditingArea}
            onChange={setMutatedVisit}
            onRequestClose={onRequestEditClose}
            onSave={onEditVisitAreaSave}
            visit={mutatedVisit || visit}
            job={job}
          />
        ) : undefined}
      </Stateless>
    );
  }
);
