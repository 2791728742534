import React, {useCallback, useEffect, useRef, useState} from "react";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import DefaultProducts from "../../../../../settings/components/default-products";
import {RaisedButton} from "material-ui";
import {EditArea} from "./area";
import {EditPlan} from "./plan";
import TextField from "material-ui/TextField";
import {EditApplicator} from "./applicator";
import {useTraceUpdate} from "./props";

export function EditProduct(props) {
  useTraceUpdate(props, "EditProduct");
  const { disabled, index, applicators, config, visit, job, bluebeamFiles, product, onChange, onRemove } = props;
  const onProductChange = useCallback((event, child, productValue) => {
    console.log("onProductChange");
    onChange({
      ...product,
      product: productValue
    })
  }, [product, onChange]);
  const onRemoveClick = useCallback(() => {
    console.log("onRemoveClick");
    if (!confirm("Are you sure you want to remove this product? This cannot be undone")) {
      return;
    }
    onRemove(product);
  }, [product, onRemove]);
  const onAreaChange = useCallback(area => {
    console.log("onAreaChange");
    onChange({
      ...product,
      area
    });
  }, [product, onChange]);
  const onPlanChange = useCallback(plan => {
    console.log("onPlanChange");
    onChange({
      ...product,
      plan
    });
  }, [product, onChange]);
  const onApplicatorsChange = useCallback(applicators => {
    console.log("onApplicatorsChange");
    onChange({
      ...product,
      applicators
    });
  }, [product, onChange]);
  const [expectedAreaString, setExpectedAreaString] = useState((product.expectedArea || "").toString());
  useEffect(() => {
    if (/^\d+(?:\.\d+)?$/.test(expectedAreaString)) {
      console.log("useEffect expectedAreaString");
      const value = +expectedAreaString;
      if (value !== product.expectedArea) {
        onChange({
          ...product,
          expectedArea: +expectedAreaString
        });
      }
    }
  }, [expectedAreaString, product, onChange]);
  const onExpectedAreaChange = useCallback(event => {
    console.log("onExpectedAreaChange");
    setExpectedAreaString(event.target.value.replace(/^\./, "0."));
  }, [setExpectedAreaString]);
  useEffect(() => {
    if (job.products.length === 1 && !product.product) {
      console.log("useEffect onProductChange");
      onProductChange(undefined, undefined, job.products[0].product);
    }
  }, [onProductChange, job, product]);
  const configProducts = (
    config
      .country_config
      .job
      .products || DefaultProducts
  );
  return (
    <div className="edit-product">
      <div className="product-select">
        <SelectField
          floatingLabelText="Product to be applied"
          hintText="Product to be applied"
          value={product.product}
          onChange={onProductChange}
          disabled={job.products.length === 1 || disabled}
        >
          {
            job.products
              .sort(({ product: a }, { product: b }) => a < b ? -1 : 1)
              .map(
                ({ product }, index) => {
                  const configProduct = configProducts.find(({ code }) => code === product);
                  return <MenuItem key={index} value={product} primaryText={configProduct ? configProduct.name : product} />
                }
              )
          }
        </SelectField>
        {
          index > 0 ? (
            <RaisedButton
              label="Remove Product"
              onClick={onRemoveClick}
              disabled={disabled}
            />
          ) : undefined
        }
      </div>
      <div className="edit-estimated-area">
        <TextField
          value={expectedAreaString}
          onChange={onExpectedAreaChange}
          floatingLabelText={<span>Estimated Area, m<sup>2</sup></span>}
          hintText={<span>Estimated Area, m<sup>2</sup></span>}
          disabled={disabled}
        />
      </div>
      <EditArea
        area={product.area}
        onChange={onAreaChange}
        disabled={disabled}
      />
      <EditPlan
        bluebeamFiles={bluebeamFiles}
        plan={product.plan}
        onChange={onPlanChange}
        job={job}
        disabled={disabled}
      />
      <EditApplicator
        applicators={product.applicators}
        availableApplicators={applicators}
        onChange={onApplicatorsChange}
        disabled={disabled}
      />
    </div>
  )
}
