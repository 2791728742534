import React from 'react';
import TextField from 'material-ui/TextField';

export default class SearchAddressTextField extends React.PureComponent {

  static propTypes = {
    onPlace: React.PropTypes.func.isRequired,
    position: React.PropTypes.object,
    // map: React.PropTypes.any,
    isScriptLoaded: React.PropTypes.bool,
    isScriptLoadSucceed: React.PropTypes.bool
  };

  unregisterListen = () => {};
  // bindMap = () => {};

  componentWillUnmount() {
    if (this.unregisterListen instanceof Function) {
      this.unregisterListen();
    }
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed, position }) {
    if (isScriptLoaded && isScriptLoadSucceed && !this.props.isScriptLoaded) {
      this.onScriptsLoaded();
    }
    if (position !== this.props.position) {
      this.setAutocompletePosition(position);
    }
    // if (map !== this.props.map) {
    //   this.bindMap(map);
    // }
  }

  onPlaceChanged(autocomplete) {
    const place = autocomplete.getPlace();
    this.props.onPlace(place);
  }

  setAutocompletePosition(position) {
    if (!this.autocomplete) {
      return;
    }
    const circle = new window.google.maps.Circle({
      center: {
        lat: position.latitude,
        lng: position.longitude
      },
      radius: 100
    });
    this.autocomplete.setBounds(circle.getBounds());
  }

  onScriptsLoaded() {
    if (!this.node) {
      return;
    }

    const input = this.node.input;

    const autocomplete = new window.google.maps.places.Autocomplete(input);

    this.autocomplete = autocomplete;

    if (this.props.position) {
      this.setAutocompletePosition(this.props.position);
    }
    //
    // this.bindMap = (map) => {
    //   if (!(map || this.props.map)) {
    //     return;
    //   }
    //   autocomplete.bindTo('bounds', (map || this.props.map));
    // };
    //
    // this.bindMap();

    const listener = this.onPlaceChanged.bind(this, autocomplete);

    autocomplete.addListener('place_changed', listener);

    this.unregisterListen = () => {
      // this.bindMap = () => {};
      window.google.maps.event.clearListeners(input, 'focus');
      window.google.maps.event.clearListeners(input, 'blur');
      window.google.maps.event.clearListeners(input, 'keydown');
    };
  }

  /**
   * @param {TextField} node
   */
  setRef(node) {
    if (!node) {
      return;
    }

    if (this.node === node) {
      return;
    }

    if (this.unregisterListen instanceof Function) {
      this.unregisterListen();
    }

    this.node = node;

    if (!(this.props.isScriptLoaded && this.props.isScriptLoadSucceed)) {
      return;
    }

    this.onScriptsLoaded();
  }

  render() {
    const inputProps = Object.assign({}, this.props);

    delete inputProps.onPlace;

    const ref = inputProps.ref || (() => {});

    if (inputProps.hasOwnProperty('isScriptLoaded')) {
      delete inputProps.isScriptLoaded;
    }
    if (inputProps.hasOwnProperty('isScriptLoadSucceed')) {
      delete inputProps.isScriptLoadSucceed;
    }
    if (inputProps.hasOwnProperty('ref')) {
      delete inputProps.ref;
    }
    if (inputProps.hasOwnProperty('ref')) {
      delete inputProps.ref;
    }
    if (inputProps.hasOwnProperty('onPlace')) {
      delete inputProps.onPlace;
    }
    if (inputProps.hasOwnProperty('position')) {
      delete inputProps.position;
    }
    // if (inputProps.hasOwnProperty('map')) {
    //   delete inputProps.map;
    // }

    return (
      <TextField
        {...inputProps}
        ref={(...args) => {
          this.setRef(...args);
          ref(...args);
        }}
      />
    );
  }

}
