import React from 'react';
import EditArea from './edit-area';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';

class EditAreaDialog extends React.PureComponent {

  render() {
    return (
      <Dialog
        title="Site Visit Area to be Applied"
        actions={[
          <RaisedButton
            onClick={this.props.onRequestClose}
            label="Cancel"
          />,
          <RaisedButton
            onClick={this.props.onSave.bind(this, this.props.visit, this.props.job)}
            label="Save"
            primary
          />
        ]}
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
      >
        <EditArea onChange={this.props.onChange} visit={this.props.visit} job={this.props.job} />
      </Dialog>
    );
  }

}

EditAreaDialog.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  onSave: React.PropTypes.func.isRequired,
  onRequestClose: React.PropTypes.func.isRequired,
  open: React.PropTypes.bool.isRequired,
  visit: React.PropTypes.object,
  job: React.PropTypes.object
};

export default EditAreaDialog;
