import React, { useMemo } from "react";
import { getTotalTime } from "./travel";
import TinyColor from "tinycolor2";
import browserHistory from "../../../../../services/history";
import TopActions from "./top-actions";
import BottomActions from "./bottom-actions";
import Info from "./info";
import { useViewType } from "../../../../../services/view-type";

function getTimeDetails(visit, moment, viewsEnabled) {
  let hasPassed = false,
    time = "";

  if (!visit.date) {
    return { hasPassed, time };
  }

  hasPassed = moment.isBefore(Date.now());

  if (moment.hour() > 0 || moment.minute() > 0) {
    time = viewsEnabled.extendedTime ? moment.format("h:mm A") : "";
  } else {
    time += "No time scheduled";
  }

  time += time ? ", " : "";
  time += moment.format(
    (viewsEnabled.extendedTime ? "dd " : "") + "DD/MM/YYYY"
  );

  return { hasPassed, time };
}

export function getVisitBaseStyle(
  visit,
  isAdmin,
  isAccounts,
  hasPassed,
  applicator
) {
  const isPassedAndSubmitted = !!(hasPassed && visit.submission);

  const applicatorColor = applicator
    ? applicator.config && applicator.config.color
    : undefined;

  return useMemo(() => {
    const visitStyle = {};

    if (applicator) {
      const color = isPassedAndSubmitted
        ? "#A9A9A9"
        : applicatorColor || "black";
      const additionalLighten = isPassedAndSubmitted ? 25 : 0;
      visitStyle.borderColor = TinyColor(color)
        .lighten(additionalLighten)
        .toString();
      visitStyle.borderWidth = 3;
      visitStyle.padding = 8;
      if (applicatorColor) {
        visitStyle.backgroundColor = TinyColor(color)
          .lighten(20 + additionalLighten)
          .toString();
      }

      if (TinyColor(visitStyle.borderColor).getLuminance() > 0.95) {
        visitStyle.borderColor = TinyColor(visitStyle.borderColor)
          .darken(10)
          .toString();
      }
    }

    if (
      (isAdmin && visit.status === "Applied") ||
      (isAccounts && visit.status === "Approved")
    ) {
      visitStyle.borderColor = "#ff4081";
      visitStyle.backgroundColor = TinyColor("#ff4081").lighten(20).toString();
    }

    return visitStyle;
  }, [
    visit.status,
    isPassedAndSubmitted,
    isAdmin,
    isAccounts,
    applicatorColor,
  ]);
}

export function getApplicator(visit, job, applicators) {
  const jobApplicatorId =
    job && job.applicator ? job.applicator.$id.$oid : null;
  const applicatorId =
    visit.applicator && visit.applicator.$id ? visit.applicator.$id.$oid : null;
  return useMemo(() => {
    const getApplicator = (id) =>
      (applicators || []).find((applicator) => applicator._id.$oid === id);
    return (
      (applicatorId && getApplicator(applicatorId)) ||
      (jobApplicatorId && getApplicator(jobApplicatorId)) ||
      null
    );
  }, [jobApplicatorId, applicatorId, applicators]);
}

export function isNumeric(value) {
  if (typeof value === "number") {
    return !isNaN(value);
  }
  if (typeof value !== "string") {
    return false;
  }
  return /^\d+(?:\.\d+)?$/i.test(value);
}

export function getQuotedMetres(job) {
  return useMemo(() => {
    if (!(job && job.products)) {
      return "";
    }
    const result = job.products
      .map((product) => product.area)
      .filter((value) => isNumeric(value))
      .map((value) => +value)
      .reduce((sum, value) => sum + value, 0);
    return Math.round(result * 100) / 100;
  }, [job ? job.products : undefined]);
}

export function getRemainingMetres(job) {
  return useMemo(() => {
    if (!(job && job.products)) {
      return "";
    }
    const result = job.products
      .map((product) => product.remaining)
      .filter((value) => isNumeric(value))
      .map((value) => +value)
      .reduce((sum, value) => sum + value, 0);
    return Math.round(result * 100) / 100;
  }, [job ? job.products : undefined]);
}

export function getRemainingVisits(job) {
  return useMemo(() => {
    if (!(job && job.sitevisits)) {
      return "NA";
    }
    if (!job.sitevisits.hasOwnProperty("quoted")) {
      return "Not Quoted";
    }
    if (!job.sitevisits.hasOwnProperty("used")) {
      return "NA";
    }
    if (!isNumeric(job.sitevisits.quoted) || !isNumeric(job.sitevisits.used)) {
      return "NA";
    }
    const remainingSiteVisits =
      Math.round((+job.sitevisits.quoted - job.sitevisits.used) * 100) / 100;
    return remainingSiteVisits < 0
      ? `Over by ${-1 * remainingSiteVisits}`
      : remainingSiteVisits;
  }, [job ? job.sitevisits : undefined]);
}

export default ({
  onFilterJob,
  isAdmin,
  isSales,
  isAccounts,
  visit,
  job,
  onScheduleVisit,
  onNewVisit,
  onAssignVisit,
  applicators,
  onDeleteVisit,
  onApproveSchedule,
  onApprovedStatus,
  onCompleteStatus,
  onVisitUpdate,
  visits,
  showScheduled,
  isViewJob,
  sidebar,
  duplicateVisit,
  addComment,
  addStaticComment,
  onEditVisitArea,
  onPickDate,
  onPickTime,
  onEditVisitInvoice,
  isAddingComment,
  isAddingStaticComment,
  moment,
  children,
  onUpdateComments,
  isDashboard,
  bluebeamFiles,
}) => {
  const viewType = useViewType(
    isDashboard ? "visit-dashboard" : "visit",
    "standard"
  );

  const viewsEnabled = useMemo(
    () => ({
      qa:
        viewType === "standard" ||
        viewType === "inf-slim" ||
        viewType === "pm-qa",
      inf: viewType === "standard" || viewType === "inf-slim",
      reducedJobNumber: viewType === "inf-slim",
      extendedTime: viewType !== "inf-slim",
      rsv:
        viewType !== "inf-slim" &&
        viewType !== "pm-slim" &&
        viewType !== "pm-qa",
      qsv:
        viewType !== "inf-slim" &&
        viewType !== "pm-slim" &&
        viewType !== "pm-qa",
      rm2: viewType !== "pm-slim" && viewType !== "pm-qa",
      qm2: viewType !== "pm-slim" && viewType !== "pm-qa",
      productValueSuffix: viewType !== "inf-slim",
      hoursTotal: viewType !== "inf-slim",
      totalTime: viewType !== "pm-qa",
      distanceTravelled: viewType !== "pm-qa",
      viewComment: viewType === "standard" || viewType === "pm-qa",
    }),
    [viewType]
  );

  const totalTime = getTotalTime(visit, viewsEnabled);
  const { hasPassed, time } = getTimeDetails(visit, moment, viewsEnabled);
  const canReSchedule = visit.submission ? !hasPassed : true;
  const applicator = getApplicator(visit, job, applicators);
  const visitStyle = getVisitBaseStyle(
    visit,
    isAdmin,
    isAccounts,
    hasPassed,
    applicator
  );
  const approvalMessage =
    visit.hasOwnProperty("scheduleChangeApprovedByAdmin") &&
    !visit.scheduleChangeApprovedByAdmin
      ? "Schedule unapproved"
      : "";

  const quotedMetres = getQuotedMetres(job);
  const remainingMetres = getRemainingMetres(job);
  const remainingSiteVisits = getRemainingVisits(job);

  const onClick = useMemo(() => {
    if (isViewJob || !job) {
      return undefined;
    }
    return () => browserHistory.push(`/job/${job._id.$oid}`);
  }, [isViewJob, job ? job._id.$oid : undefined, browserHistory]);

  // These are actually static, previously they changed
  const iconStyles = useMemo(() => {
    const base = {
      iconButtonStyle: {},
      iconButtonSVGStyle: {},
      iconButtonTooltipStyle: {},
    };
    if (!visit.date || onDeleteVisit) {
      return base;
    }
    base.iconButtonStyle.width = 25;
    base.iconButtonStyle.height = 25;
    base.iconButtonStyle.padding = 5;
    base.iconButtonSVGStyle.width = 15;
    base.iconButtonSVGStyle.height = 15;
    base.iconButtonTooltipStyle.width = "auto";
    return base;
  }, [!!visit.date, !onDeleteVisit]);
  let pm = false;

  if (applicator) {
    pm = (applicator.name || applicator.username)
      .toUpperCase()
      .includes("PM STATUS CHECK");
  }

  const sortedApplicators = useMemo(() => {
    if (!applicators) {
      return [];
    }
    return applicators.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }, [applicators]);

  return (
    <div
      className={`visit ${sidebar ? `sidebar-visits` : ""} ${
        visit.qa ? "qa-enabled" : ""
      } ${!visit.date ? "unscheduled" : ""}`}
      data-dragging="false"
      data-drag-preview="false"
      style={visitStyle}
      data-visit-submitted={!!visit.submission}
      data-visit-scheduled={!!visit.date}
      data-filter-qa={visit.qa}
      data-filter-inf={visit.inf}
      data-filter-billed={visit.invoice && visit.invoice.number}
      data-filter-pm={pm}
      onClick={onClick}
    >
      {!visit.date ? (
        <div className="unscheduled-indicator">UNSCHEDULED</div>
      ) : null}
      <div className="visit-inner">
        {!sidebar && job ? (
          <TopActions
            viewsEnabled={viewsEnabled}
            onUpdateComments={onUpdateComments}
            isAdmin={isAdmin}
            isAccounts={isAccounts}
            isViewJob={isViewJob}
            job={job}
            visit={visit}
            iconButtonStyle={iconStyles.iconButtonStyle}
            iconButtonSVGStyle={iconStyles.iconButtonSVGStyle}
            iconButtonTooltipStyle={iconStyles.iconButtonTooltipStyle}
            duplicateVisit={duplicateVisit}
            addComment={addComment}
            addStaticComment={addStaticComment}
          />
        ) : undefined}
        <Info
          onFilterJob={onFilterJob}
          isAdmin={isAdmin}
          isAccounts={isAccounts}
          viewsEnabled={viewsEnabled}
          onUpdateComments={onUpdateComments}
          sidebar={sidebar}
          approvalMessage={approvalMessage}
          applicator={applicator}
          job={job}
          visit={visit}
          time={time}
          iconButtonTooltipStyle={iconStyles.iconButtonTooltipStyle}
          quotedMetres={quotedMetres}
          remainingMetres={remainingMetres}
          remainingSiteVisits={remainingSiteVisits}
          totalTime={totalTime}
          isAddingComment={isAddingComment}
          isAddingStaticComment={isAddingStaticComment}
          onDeleteVisit={onDeleteVisit}
        />
        {!sidebar && job ? (
          <BottomActions
            bluebeamFiles={bluebeamFiles}
            viewsEnabled={viewsEnabled}
            onVisitUpdate={onVisitUpdate}
            canReSchedule={canReSchedule}
            isAccounts={isAccounts}
            isSales={isSales}
            isAdmin={isAdmin}
            iconButtonStyle={iconStyles.iconButtonStyle}
            iconButtonSVGStyle={iconStyles.iconButtonSVGStyle}
            iconButtonTooltipStyle={iconStyles.iconButtonTooltipStyle}
            applicators={sortedApplicators}
            visit={visit}
            job={job}
            onDeleteVisit={onDeleteVisit}
            onEditVisitArea={onEditVisitArea}
            onPickDate={onPickDate}
            onPickTime={onPickTime}
            onAssignVisit={onAssignVisit}
            onApproveSchedule={onApproveSchedule}
            onApprovedStatus={onApprovedStatus}
            onEditVisitInvoice={onEditVisitInvoice}
            visits={visits}
          />
        ) : undefined}
      </div>
      {children}
    </div>
  );
};
