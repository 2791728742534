import { createElement, lazy, Suspense, forwardRef } from "react";
import Loading from "../modules/app/callback";

function lazyBase(factory) {
  const Component = lazy(factory);
  return forwardRef((props, ref) => {
    return createElement(
      Suspense,
      {
        fallback: createElement(Loading)
      },
      createElement(
        Component,
        {
          ...props,
          ref
        }
      )
    )
  })
}

// Will return a function that will create our component
// once we are ready to use it
//
// Any call after will return the same initial component
export function lazyCreator(factory) {
  let initialised;
  return () => {
    return initialised = initialised || lazyBase(factory)
  }
}

export function superLazyRender(factory) {
  const Component = superLazy(factory);
  return (props) => createElement(Component, props);
}

export function superLazy(factory) {
  const creator = lazyCreator(factory);
  return forwardRef((props, ref) => {
    const Component = creator();
    return createElement(
      Component,
      {
        ...props,
        ref
      }
    );
  });
}

export default lazyBase;
