import React, { useEffect, useCallback, Fragment } from "react";
import Dialog from 'material-ui/Dialog';
import RaisedButton from "material-ui/RaisedButton";
import { EditProduct } from "./product";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import {useTraceUpdate} from "./props";
import {EditApplicator} from "./applicator";

const TYPE = [
  "Application",
  "Induction"
];

const STATE = [
  "Pending",
  "Scheduled",
  "Commenced",
  "Cancelled",
  "Completed",
  "Archived"
];

// Only the app should be doing this
const DISABLE_STATES = [
  "Commenced",
  "Completed"
];

export function EditProductModal(props) {
  useTraceUpdate(props, "EditProductModal");
  const { loading: disabled, type, state, onState, applicators, config, onRemoveProduct, onRequestClose, onSave, isOpen, job, visit, products, bluebeamFiles, onNewProduct, onProductChange, onType, input, setApplicators } = props;
  useEffect(() => {
    if (isOpen && products.length === 0) {
      onNewProduct();
    }
  }, [isOpen, onNewProduct, products]);
  const onStateChange = useCallback((event, child, value) => {
    onState(value);
  }, [onState]);
  const onTypeChange = useCallback((event, child, value) => {
    onType(value)
  }, [onType])
  return (
    <Dialog
      title="Edit Site Visit"
      actions={[
        <RaisedButton
          onClick={onRequestClose}
          label="Cancel"
          disabled={disabled}
        />,
        <RaisedButton
          onClick={onSave}
          label="Save"
          primary
          disabled={disabled}
        />
      ]}
      open={isOpen}
      onRequestClose={disabled ? () => {} : onRequestClose}
      disableBackdropClick={disabled}
      disableEscapeKeyDown={disabled}
    >
      <div className="edit-visit-state">
        <SelectField
          floatingLabelText="Type"
          hintText="Type"
          value={type}
          onChange={onTypeChange}
          disabled={disabled}
        >
          {
            TYPE.map(type => <MenuItem key={type} value={type} primaryText={type} />)
          }
        </SelectField>
      </div><div className="edit-visit-state">
      <SelectField
        floatingLabelText="Status"
        hintText="Status"
        value={state}
        onChange={onStateChange}
        disabled={disabled}
      >
        {
          STATE.map(state => <MenuItem disabled={DISABLE_STATES.includes(state)} key={state} value={state} primaryText={state} />)
        }
      </SelectField>
    </div>
      {
        type === "Application" ? (
          job.products.length === 0 ? (
            <p>No products to select, please add products to the job</p>
          ) : (
            <div className="edit-visit-products">
              {
                products.map(
                  (product, index) => (
                    <EditProduct
                      key={product.key}
                      job={job}
                      visit={visit}
                      product={product}
                      bluebeamFiles={bluebeamFiles}
                      onChange={onProductChange}
                      config={config}
                      onRemove={onRemoveProduct}
                      applicators={applicators}
                      index={index}
                      disabled={disabled}
                    />
                  )
                )
              }
              <RaisedButton
                disabled={disabled}
                onClick={onNewProduct}
                label="Add Product"
              />
            </div>
          )
        ) : (
          <div className="edit-visit-products">
            <EditApplicator
              noInfo
              applicators={input.applicators}
              availableApplicators={applicators}
              onChange={setApplicators}
              disabled={disabled}
            />
          </div>
        )
      }
    </Dialog>
  )
}
