import React from "react";
import AppBar from "material-ui/AppBar";
import FlatButton from "material-ui/FlatButton";
import { Link } from "react-router-dom";
import browserHistory from "../../../../services/history";
import SocialNotifications from "material-ui/svg-icons/social/notifications-active";
import SocialPeople from "material-ui/svg-icons/social/people";
import ActionSettings from "material-ui/svg-icons/action/settings";
import ActionLock from "material-ui/svg-icons/action/lock";
import ActionTrendingUp from "material-ui/svg-icons/action/trending-up";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Badge from "material-ui/Badge";
import { injectUser } from "../../../../services/user";
import { injectConfig } from "../../../../services/config";
import CustomIcons from "../../../../components/icons/customIcons";
import { fetch } from "../../../../services/fetch";
import Authentication from "../../../../services/authentication";
import Logo from "../../../../images/markhams-gobal.png";

class Header extends React.Component {
  state = {
    selectedCountry: "NZL",
    notifications: 0,
  };

  static GLOBAL_NOTIFICATION_PROMISE;

  constructor(props) {
    super(props);
    this.setCountryToConfig();
  }

  async componentDidMount() {
    await this.listen();
    return this.fetchNotifications();
  }

  componentWillUnmount() {
    if (this.unregisterListen instanceof Function) {
      this.unregisterListen();
    }
  }

  listen() {
    if (this.unregisterListen instanceof Function) {
      this.unregisterListen();
    }
    let timeout, browserListen;
    const handler = () => {
      this.fetchNotificationsDeferred().catch(console.warn);
      // Trigger a couple a little after
      // setTimeout(this.fetchNotifications.bind(this), 500);
      timeout = setTimeout(this.fetchNotifications.bind(this), 10000);
    };

    browserListen = browserHistory.listen(handler);

    this.unregisterListen = () => {
      if (browserListen instanceof Function) {
        browserListen();
        browserListen = null;
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  async fetchNotificationsDeferred() {
    if (!Header.GLOBAL_NOTIFICATION_PROMISE) {
      Header.GLOBAL_NOTIFICATION_PROMISE = Promise.resolve();
    }
    Header.GLOBAL_NOTIFICATION_PROMISE =
      Header.GLOBAL_NOTIFICATION_PROMISE.then(
        this.fetchNotifications.bind(this)
      );
    return Header.GLOBAL_NOTIFICATION_PROMISE;
  }

  async fetchNotifications() {
    return this.fetchNotificationsClient();
  }

  async fetchNotificationsClient() {
    const promise = new Promise((resolve) =>
      this.setState(
        {
          loading: true,
        },
        resolve
      )
    );

    const response = await fetch("/notification/getNotificationsCount", {
      method: "GET",
      credentials: "include",
    });

    const notifications = await response.json();

    await promise;

    return new Promise((resolve) =>
      this.setState(
        {
          notifications,
          loading: false,
        },
        resolve
      )
    );
  }

  setCountryToConfig() {
    this.state.selectedCountry =
      this.props.config.country_code || this.state.selectedCountry;
  }

  async onSelectCountry(event, index, value) {
    await new Promise((resolve) =>
      this.setState(
        {
          selectedCountry: value,
        },
        resolve
      )
    );
    browserHistory.push(`/config/changeCountry/${value}`);
  }

  render() {
    const countries = this.props.config.available_countries || [];

    const isAdmin = this.props.roles.isOrChild(this.props.user.role, "admin"),
      isCountryAdmin = this.props.roles.isOrChild(
        this.props.user.role,
        "country_admin"
      ),
      isSales = this.props.roles.isOrChild(this.props.user.role, "sales"),
      isAccounts = this.props.roles.isOrChild(this.props.user.role, "accounts"),
      isReports = isSales || isAccounts || isAdmin || isCountryAdmin;

    const notificationsButton = (
      <FlatButton
        icon={
          <SocialNotifications
            data-notifications={!!this.state.notifications}
          />
        }
        label="Notifications"
      />
    );

    return (
      <AppBar
        className="app-bar"
        iconElementLeft={
          <div className="brand-icon-section">
            <Link to="/dashboard">
              <img src={Logo} role="presentation" className="logo" />
            </Link>
            {countries.length > 1 ? (
              <SelectField
                value={this.state.selectedCountry}
                onChange={this.onSelectCountry.bind(this)}
                style={{
                  width: 100,
                  background: "#6D6D6D",
                  padding: "8px",
                  color: "white",
                }}
                labelStyle={{ color: "white" }}
                underlineStyle={{
                  borderColor: "white",
                  backgroundColor: "white",
                }}
                iconStyle={{ fill: "white" }}
              >
                {countries.map((country, index) => (
                  <MenuItem
                    key={index}
                    value={country.code}
                    primaryText={country.short_name || country.code}
                  />
                ))}
              </SelectField>
            ) : null}
          </div>
        }
        style={{ paddingRight: "0px" }}
      >
        <div className="app-bar-icons">
          <Link to="/dashboard">
            <FlatButton
              icon={
                <CustomIcons
                  height="24"
                  width="24"
                  icon="calendar"
                  style={{
                    verticalAlign: "middle",
                    fill: "rgba(0, 0, 0, 0.54)",
                  }}
                />
              }
              label="Calendar"
            />
          </Link>
          <Link to="/visits">
            <FlatButton
              icon={
                <CustomIcons
                  height="24"
                  width="24"
                  icon="van"
                  style={{
                    verticalAlign: "middle",
                    fill: "rgba(0, 0, 0, 0.54)",
                  }}
                />
              }
              label="Site Visits"
            />
          </Link>
          <Link to="/jobs">
            <FlatButton
              icon={
                <CustomIcons
                  height="23"
                  width="23"
                  icon="jobs"
                  style={{
                    verticalAlign: "middle",
                    fill: "rgba(0, 0, 0, 0.54)",
                  }}
                />
              }
              label="Jobs"
            />
          </Link>
          {isReports ? (
            <Link to="/reports">
              <FlatButton icon={<ActionTrendingUp />} label="Reports" />
            </Link>
          ) : null}
          {isCountryAdmin ? (
            <React.Fragment>
              <Link to="/users">
                <FlatButton icon={<SocialPeople />} label="Users" />
              </Link>
              <Link to="/settings">
                <FlatButton icon={<ActionSettings />} label="Settings" />
              </Link>
            </React.Fragment>
          ) : null}
          <FlatButton
            icon={<ActionLock />}
            label="Logout"
            onClick={Authentication.logout}
          />
          {countries.length > 1 ? (
            <SelectField
              value={this.state.selectedCountry}
              onChange={this.onSelectCountry.bind(this)}
              style={{
                width: 100,
                background: "#6D6D6D",
                padding: "8px",
                color: "white",
              }}
              labelStyle={{ color: "white" }}
              underlineStyle={{
                borderColor: "white",
                backgroundColor: "white",
              }}
              iconStyle={{ fill: "white" }}
            >
              {countries.map((country, index) => (
                <MenuItem
                  key={index}
                  value={country.code}
                  primaryText={country.short_name || country.code}
                />
              ))}
            </SelectField>
          ) : null}
        </div>
      </AppBar>
    );
  }
}

Header.propTypes = {
  user: React.PropTypes.object,
  roles: React.PropTypes.object,
  config: React.PropTypes.object,
};

export default injectConfig(injectUser(Header));
