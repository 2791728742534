import React, {Fragment, useState, useCallback, useContext, useEffect} from "react";
import { EditProductModal } from "./modal";
import IconButton from "material-ui/IconButton";
import ModeEdit from "material-ui/svg-icons/editor/mode-edit";
import UUID from "uuid";
import {ConfigContext} from "../../../../../../services/config";
import useAsyncFn from "react-use/esm/useAsyncFn";
import {useTraceUpdate} from "./props";

function getDefaultState(visit) {
  if (visit.state) {
    return visit.state;
  }
  if (visit.status) {
    if (visit.status === "Pending") {
      return "Pending";
    }
    if (visit.status === "Applied" || visit.status === "Approved" || visit.status === "Complete") {
      return "Completed"
    }
  }
  return "Pending";
}

export function EditProductsModalButton(props) {
  useTraceUpdate(props, "EditProductsModalButton");
  const { onVisitUpdate, applicators, visit, job, bluebeamFiles, iconButtonTooltipStyle, iconButtonStyle, iconButtonSVGStyle } = props;
  const { config } = useContext(ConfigContext);
  const [isOpen, setOpen] = useState(false);

  // See BasicStateValues
  const [state, setState] = useState(getDefaultState(visit));

  // Allows for external update to happen
  useEffect(() => {
    if (visit.state) {
      setState(visit.state);
    }
  }, [visit.state, setState]);

  // See SiteVisitTaskInput
  const [input, setInput] = useState(visit.input || {
    products: [],
    type: "Application",
    comments: "",
    area: [],
    accessDetails: "",
    // This is used for type: "Induction", for type: "Application" applicators are assigned directly to
    // a product
    applicators: []
  });

  const setType = useCallback((type) => setInput(input => ({
    ...input,
    type
  })), [setInput])
  // See note on applicators above
  const setApplicators = useCallback((applicators) => setInput(input => ({
    ...input,
    applicators
  })), [setInput])


  useEffect(() => {
    if (visit.input) {
      setInput(visit.input);
    }
  }, [visit.input, setInput]);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const onProductChange = useCallback(product => {
    console.log("onProductChange", product);
    setInput(input => {
      const nextProducts = input.products.slice();
      const index = nextProducts.findIndex(({ key }) => product.key === key);
      if (index === -1) {
        nextProducts.push(product);
      } else {
        nextProducts[index] = product;
      }
      return {
        ...input,
        products: nextProducts
      }
    });
  }, [input, setInput]);
  const onRemoveProduct = useCallback(product => {
    setInput(input => ({
      ...input,
      products: input.products.filter(({ key }) => key !== product.key)
    }));
  }, [setInput]);
  const onNewProduct = useCallback(() => {
    onProductChange({
      key: UUID.v4(),
      area: [],
      applicators: [],
      product: "",
      expectedArea: undefined,
      expectedLitres: undefined,
      plan: {
        file: undefined,
        page: 0
      }
    });
  }, [onProductChange]);

  const [saveState, save] = useAsyncFn(async () => {
    await onVisitUpdate({
      input,
      state
    });
    onClose();
  }, [state, input, onClose, onVisitUpdate]);

  useEffect(() => {
    if (saveState.error) {
      console.error(saveState);
      alert("Failed to save");
    }
  }, [saveState.error]);

  return (
    <Fragment>
      <IconButton
        title="Edit Products"
        alt="Edit Products"
        tooltipPosition="bottom-center"
        style={iconButtonStyle}
        iconStyle={Object.assign({}, iconButtonSVGStyle, {fill: '#000'})}
        tooltipStyles={iconButtonTooltipStyle}
        onClick={onOpen}
      >
        <ModeEdit />
      </IconButton>
      <EditProductModal
        config={config}
        state={state}
        onState={setState}
        visit={visit}
        bluebeamFiles={bluebeamFiles}
        isOpen={isOpen}
        onRequestClose={onClose}
        onProductChange={onProductChange}
        onNewProduct={onNewProduct}
        job={job}
        products={input.products}
        onRemoveProduct={onRemoveProduct}
        applicators={applicators}
        onSave={save}
        loading={saveState.loading}
        onType={setType}
        type={input.type}
        input={input}
        setApplicators={setApplicators}
      />
    </Fragment>
  );
}
