import Auth0 from 'auth0-js';
import Config from './static-config';
import Storage from './storage';
import History from './history';

class Authentication {

  authentication = new Auth0.WebAuth(Config.auth);

  constructor(history) {
    this.history = history;
    this.scheduleRenewal();
  }

  handleAuthentication = async () => {
    const success = await new Promise(
      resolve => {
        this.authentication.parseHash(this.onAuthResult(resolve))
      }
    );
    if (success) {
      // navigate to the home route
      this.history.replace('/home');
    }
    return success;
  };

  onAuthResult(resolve) {
    return (error, authResult) => {
      if (error) {
        return resolve(false);
      }
      if (!(authResult && authResult.accessToken && authResult.idToken)) {
        return resolve(false);
      }
      this.setSession(authResult);
      return resolve(true);
    }
  };

  setSession = authResult => {
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    Storage.setItem('access_token', authResult.accessToken);
    Storage.setItem('id_token', authResult.idToken);
    Storage.setItem('expires_at', expiresAt);
    this.scheduleRenewal();
  };

  renewSession = () => {
    return new Promise(
      resolve => {
        this.authentication.checkSession({}, this.onAuthResult(resolve))
      }
    );
  };

  scheduleRenewal = () => {
    const expiresAt = this.getExpiry();
    const delay = expiresAt - Date.now();
    if (delay <= 0) {
      return;
    }
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }
    this.tokenRenewalTimeout = setTimeout(this.renewSession, delay);
  };

  logout = async () => {
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }
    Storage.removeItem('access_token');
    Storage.removeItem('id_token');
    Storage.removeItem('expires_at');
    return this.authentication.logout({
      returnTo: location.origin
    });
  };

  getAccessToken = () => {
    if (!this.isAuthenticated()) {
      return null;
    }
    return Storage.getItem('access_token');
  };

  login = () => {
    return this.authentication.authorize();
  };

  getExpiry = () => {
    const json = Storage.getItem('expires_at');
    if (!json) {
      return 0;
    }
    let expiresAt;
    try {
      expiresAt = JSON.parse(json)
    } catch(e) {
      return 0;
    }
    return expiresAt;
  };

  isAuthenticated = () => {
    const expiresAt = this.getExpiry();
    return Date.now() < expiresAt;
  };



}

export {
  Authentication
}

export default new Authentication(History);
