import React, { useCallback, useEffect, useMemo, useRef } from "react";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "material-ui/svg-icons/action/info";
import { useTraceUpdate } from "./props";

// Taken from bluebeam-service
function getBluebeamProjectName(job) {
  const completed = " [Completed]";

  // 60 is the max a name can be in Bluebeam
  let maxNameLength = 60 - job.site.region.length - job.number.length - 6;
  if (job.completedAt) {
    maxNameLength -= completed.length;
  }
  const name = `${job.site.region} - ${job.number} - ${job.site.name.substr(
    0,
    maxNameLength
  )}`;
  return job.completedAt ? `${name}${completed}` : name;
}

export function EditPlan(props) {
  useTraceUpdate(props, "EditPlan");
  const { disabled, bluebeamFiles, plan, onChange, job } = props;
  const onFileChange = useCallback(
    (event, child, file) => {
      onChange({
        ...plan,
        file,
      });
    },
    [plan, onChange]
  );

  const onPageChange = useCallback(
    (event, child, page) => {
      onChange({
        ...plan,
        page: +page,
      });
    },
    [onChange, plan]
  );

  const file = useMemo(() => {
    if (!plan.file) {
      return undefined;
    }
    return bluebeamFiles.find((file) => file.name === plan.file);
  }, [plan, bluebeamFiles]);

  useEffect(() => {
    if (bluebeamFiles.length === 1 && !plan.file) {
      onFileChange(undefined, undefined, bluebeamFiles[0].name);
    }
  }, [bluebeamFiles, onFileChange, plan]);

  const bluebeamProjectName = useMemo(() => getBluebeamProjectName(job), [job]);

  if (plan.file && !file) {
    return (
      <p>
        Could not find selected file for this product, please contact support
      </p>
    );
  }

  if (!bluebeamFiles || bluebeamFiles.length === 0) {
    return (
      <p>No plans to select, please upload a PDF to the "Markups" folder</p>
    );
  }

  return (
    <div className="edit-plan">
      <div className="with-info">
        <SelectField
          floatingLabelText="Bluebeam Master File"
          hintText="Bluebeam Master File"
          value={plan.file}
          onChange={onFileChange}
          disabled={bluebeamFiles.length === 1 || disabled}
        >
          {bluebeamFiles
            .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
            .map(({ name, _id }) => {
              return (
                <MenuItem key={_id.$oid} value={name} primaryText={name} />
              );
            })}
        </SelectField>
        <Tooltip
          title={`You can view the current plans available within Bluebeam with the name "${bluebeamProjectName}"`}
        >
          <Info />
        </Tooltip>
      </div>
      {file && file.pages ? (
        <SelectField
          floatingLabelText="Page"
          hintText="Page"
          value={plan.page}
          onChange={onPageChange}
          disabled={file.pages.length === 1 || disabled}
        >
          {file.pages.map((_, index) => {
            return (
              <MenuItem
                key={index}
                value={index}
                primaryText={`Page ${index + 1}`}
              />
            );
          })}
        </SelectField>
      ) : undefined}
    </div>
  );
}
