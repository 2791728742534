import Moment from "moment";
import React, { useMemo } from "react";
import DashboardComments from "../../../dashboardComments";

function displayComments({ viewsEnabled, visit, onDeleteVisit, job, isAddingComment, isAddingStaticComment }) {
  const comment = visit.latestComment || (!onDeleteVisit && job.latestComment);
  const dateString = visit.latestCommentDate || job.latestCommentDate;
  return useMemo(() => {
    if (!(viewsEnabled.viewComment && comment && !(isAddingStaticComment || isAddingComment))) {
      return <span />;
    }
    const date = dateString ? Moment(dateString.$date || dateString).format('DD/MM/YY ') : '';
    return (
      <div className="comment-section" onClick={(event) => event.stopPropagation()}>
        <div className="latest-comment-section-wrapper">
          <div className="latest-comment-section">
            <div className="latest-comment-section-comment">
              <div className="visit-recent-comment">
                {
                  date && comment.indexOf(date) === -1 ? (
                    <span>{date}</span>
                  ) : undefined
                }
                {comment}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [viewsEnabled.viewComment, comment, dateString, isAddingComment, isAddingStaticComment]);
}

function createComment({ visit, job, isAddingComment, isAddingStaticComment, onUpdateComments }) {
  return useMemo(() => {
    if (!((isAddingStaticComment || isAddingComment) && visit && visit._id && job && job._id && onUpdateComments)) {
      return undefined;
    }
    return (
      <div className="add-comment-section" onClick={(event) => event.stopPropagation()}>
        <div className="add-comment">
          <DashboardComments
            key={(isAddingStaticComment || false).toString()}
            onComments={onUpdateComments}
            job={job}
            visit={visit}
            value={isAddingStaticComment ? (visit.staticComment || "") : undefined}
            static={isAddingStaticComment}
          />
        </div>
      </div>
    );
  }, [visit, job, isAddingComment, isAddingStaticComment, onUpdateComments])
}

export default ({ viewsEnabled, visit, onDeleteVisit, job, isAddingComment, isAddingStaticComment, onUpdateComments }) => {

  const displayComment = displayComments({ viewsEnabled, visit, onDeleteVisit, job, isAddingComment, isAddingStaticComment });
  const addComment = createComment({ viewsEnabled, visit, job, isAddingComment, isAddingStaticComment, onUpdateComments });

  return (
    <React.Fragment>
      {displayComment}
      {addComment}
    </React.Fragment>
  )
}
