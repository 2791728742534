import React, { useMemo } from "react";
import BooleanButton from "../boolean-button";
import {
  InfButton,
  QaButton,
} from "../../../../../../components/icons/customIcons";
import Applicator from "./applicator";
import Comments from "./comments";

export default ({
  onFilterJob,
  isAdmin,
  isAccounts,
  sidebar,
  approvalMessage,
  applicator,
  job,
  visit,
  time,
  iconButtonTooltipStyle,
  quotedMetres,
  remainingMetres,
  remainingSiteVisits,
  totalTime,
  isAddingComment,
  isAddingStaticComment,
  onDeleteVisit,
  onUpdateComments,
  viewsEnabled,
}) => {
  const { handover } = job;
  return (
    <div className="visit-details">
      <div className="visit-applicator-section">
        <Applicator
          onFilterJob={onFilterJob}
          isAdmin={isAdmin}
          isAccounts={isAccounts}
          viewsEnabled={viewsEnabled}
          sidebar={sidebar}
          applicator={applicator}
          job={job}
          visit={visit}
          time={time}
        />
        {!sidebar && viewsEnabled.qa ? (
          <div
            className="visit-qa-section"
            onClick={(event) => event.stopPropagation()}
          >
            <BooleanButton
              id={visit._id}
              component={QaButton}
              initialValue={visit.qa}
              iconButtonTooltipStyle={iconButtonTooltipStyle}
              name="QA"
              url="/visit/qaToggleVisit"
            />
          </div>
        ) : null}
      </div>

      {handover && handover.products && handover.products.length ? (
        <div className="visit-tags">
          {handover.products.map((product, index) => (
            <div className="visit-tag" key={index}>
              #{product.name.replace(" ", "-").toUpperCase()}
            </div>
          ))}
        </div>
      ) : null}

      <div className="visit-name-section">
        <div className="visit-name">{job.site.name.trim()}</div>
        {!sidebar && viewsEnabled.inf ? (
          <div
            className="visit-qa-section"
            onClick={(event) => event.stopPropagation()}
          >
            <BooleanButton
              id={visit._id}
              component={InfButton}
              initialValue={visit.inf}
              iconButtonTooltipStyle={iconButtonTooltipStyle}
              name="INF"
              url="/visit/infToggleVisit"
            />
          </div>
        ) : null}
      </div>
      <div className="visit-details-inner-section">
        <div className="visit-details-inner-column">
          {viewsEnabled.qm2 ? (
            <div className="visit-details-inner-row-item">
              Qm<sup>2</sup>:{" "}
              <span>
                {quotedMetres != null ? (
                  <React.Fragment>
                    {quotedMetres}
                    <sup>2</sup>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : undefined}

          {!sidebar ? (
            <React.Fragment>
              {viewsEnabled.qsv ? (
                <div className="visit-details-inner-row-item">
                  QSV:{" "}
                  <span>
                    {job.sitevisits && job.sitevisits.hasOwnProperty("quoted")
                      ? job.sitevisits.quoted
                      : "Not Quoted"}
                  </span>
                </div>
              ) : undefined}
              {visit.invoice && visit.invoice.number ? (
                <div className="visit-details-inner-row-item">
                  <span>INV: {visit.invoice.number}</span>
                </div>
              ) : undefined}
            </React.Fragment>
          ) : undefined}
        </div>
        <div className="visit-details-inner-column">
          {viewsEnabled.rm2 ? (
            <div className="visit-details-inner-row-item">
              Rm<sup>2</sup>:{" "}
              <span>
                {remainingMetres != null ? (
                  <React.Fragment>
                    {remainingMetres}
                    <sup>2</sup>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : undefined}
          {!sidebar && viewsEnabled.rsv ? (
            <div className="visit-details-inner-row-item">
              RSV:&nbsp;<span>{remainingSiteVisits}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="visit-product-remaining">
          {useMemo(
            () =>
              visit.submission ? (
                <div className="visit-product-list">
                  {visit.submission.product_applied
                    ? visit.submission.products.map((product, index) => {
                        // Coverage rate m2/L
                        let productCoverage = "";
                        if (!isNaN(+product.litres) && !isNaN(+product.area)) {
                          const productCoverageNumber =
                            Math.floor(
                              (+product.area / +product.litres) * 100
                            ) / 100;
                          if (!isNaN(productCoverageNumber)) {
                            productCoverage = (
                              <span>
                                , {productCoverageNumber}m<sup>2</sup>/l
                              </span>
                            );
                          }
                        }
                        return (
                          <div key={index}>
                            {job.products[index]
                              ? job.products[index].productOther ||
                                job.products[index].product
                              : "Deleted product"}
                            : {product.area || 0}m<sup>2</sup>
                            {viewsEnabled.productValueSuffix
                              ? " applied"
                              : undefined}
                            , {product.litres || 0}l{" "}
                            {viewsEnabled.productValueSuffix
                              ? " used"
                              : undefined}
                            {productCoverage}
                          </div>
                        );
                      })
                    : "No product applied"}
                </div>
              ) : (
                <div className="visit-product-list">
                  {job.products.map((product, index) => {
                    const productName = product.productOther || product.product,
                      productArea = ((visit.area || [])[index] || {}).value;

                    return (
                      <div key={index}>
                        {productName}
                        {": test "}
                        {productArea ? (
                          <span>
                            {productArea}m<sup>2</sup>
                            {viewsEnabled.productValueSuffix
                              ? " to be applied"
                              : undefined}
                          </span>
                        ) : (
                          <span>
                            {Math.round(product.remaining * 100) / 100}m
                            <sup>2</sup>
                            {viewsEnabled.productValueSuffix
                              ? " remaining"
                              : undefined}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              ),
            [
              viewsEnabled.productValueSuffix,
              job.products,
              visit.area,
              !!visit.submission,
              visit.submission ? visit.submission.product_applied : undefined,
              visit.submission ? visit.submission.products : undefined,
            ]
          )}
          {viewsEnabled.totalTime && totalTime ? (
            <div className="visit-product-list">{totalTime}</div>
          ) : null}
          {viewsEnabled.distanceTravelled && visit.submission ? (
            <div className="visit-product-list">
              Distance travelled (km):{" "}
              {visit.submission.travel_distance || "Not provided"}
            </div>
          ) : null}
        </div>
      </div>
      <Comments
        viewsEnabled={viewsEnabled}
        onUpdateComments={onUpdateComments}
        visit={visit}
        onDeleteVisit={onDeleteVisit}
        job={job}
        isAddingComment={isAddingComment}
        isAddingStaticComment={isAddingStaticComment}
      />
      {approvalMessage ? (
        <div className="visit-approval-message">{approvalMessage}</div>
      ) : undefined}
    </div>
  );
};
