import 'whatwg-fetch';
import Config from './static-config';
import Authentication from './authentication';

export async function getHeaders(path) {
  if (/access_token=/i.test(path)) {
    return {};
  }
  if (!Authentication.isAuthenticated()) {
    const success = await Authentication.renewSession();
    if (!success) {
      await Authentication.logout();
      return {};
    }
  }
  return {
    Authorization: `Bearer ${Authentication.getAccessToken()}`
  };
}

function getUrl(path) {
  if (/^(https?|blob|data):(?:\/\/)?/i.test(path)) {
    return path;
  }
  return `${Config.api.url.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
}

export async function fetch(path, options = {}) {
  const headers = await getHeaders(path);
  return window.fetch(getUrl(path), {
    credentials: 'include',
    ...options,
    headers: {
      ...(options.headers || {}),
      ...headers
    }
  })
}
