import React from 'react';
import { injectUser } from '../../../services/user';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { fetch } from '../../../services/fetch';

class Comments extends React.PureComponent {

  state = {
    savingComment: false,
    commentText: undefined
  };

  static getDerivedStateFromProps(props, state) {
    return {
      commentText: typeof state.commentText === "string" ? state.commentText : (props.value || "")
    };
  }

  onCommentTextChange(event) {
    return new Promise((resolve) => this.setState({
      commentText: event.target.value
    }, resolve));
  }

  async submitOnEnter(event) {
    if (event.keyCode !== 13) {
      return null;
    }
    return this.submitComment();
  }

  async submitComment() {
    await new Promise((resolve) => this.setState({
      savingComment: true
    }, resolve));

    const response = await fetch('/job/createComment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json+bson'
      },
      body: JSON.stringify({
        _id: this.props.job._id,
        visit_id: this.props.visit && this.props.visit._id,
        commentText: this.state.commentText,
        static: this.props.static
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      await new Promise((resolve) => this.setState({
        savingComment: false
      }, resolve));
      return alert("Could not save comment");
    }

    // const comments = this.props.comments.slice();

    // comments.unshift(comment);

    const updatePromise = this.props.onComments();

    const promise = new Promise((resolve) => this.setState({
      savingComment: false,
      commentText: ''
    }, resolve));

    await updatePromise;
    await promise;
  }

  render() {
    return (
      <div className="comment-section flex-grid">
        <div className="flex-grid column article-comment-submit">
          <TextField
              id="filled-multiline-static"
              placeholder={`Type ${this.props.static ? 'static ' : ''}comment here...`}
              multiLine={true}
              name="comment_text"
              rows={3}
              fullWidth
              margin="normal"
              variant="filled"
              value={this.state.commentText || ""}
              onChange={this.onCommentTextChange.bind(this)}
              disabled={this.state.savingComment}
              onKeyDown={this.submitOnEnter.bind(this)}
              style={{background:'#E9E9E9', marginBottom:'5px', padding:'8px', marginLeft:'-8px'}}
              underlineStyle={{border: '0px'}}
            />
          <div>
            <RaisedButton
              className="submit-button"
              fullWidth
              disabled={this.state.savingComment || !(this.props.static || this.state.commentText.trim().length)}
              onClick={this.submitComment.bind(this)}
            >
              {`${this.props.value ? "Update" : "Add"} ${this.props.static ? 'Static ' : ''}Comment`}
            </RaisedButton>
          </div>
        </div>
      </div>
    )
  }

}

Comments.propTypes = {
  intl: intlShape,
  comments: PropTypes.arrayOf(
    PropTypes.shape(
      {
        _id: PropTypes.shape({
          $oid: PropTypes.string.isRequired
        }).isRequired,
        user: PropTypes.shape({
          $id: PropTypes.shape({
            $oid: PropTypes.string.isRequired
          }).isRequired
        }).isRequired,
        text: PropTypes.string.isRequired,
        date: PropTypes.shape({
          $date: PropTypes.string.isRequired
        }),
        readOnly: PropTypes.bool
      }
    ).isRequired
  ),
  onComments: PropTypes.func.isRequired,
  job: PropTypes.shape(
    {
      _id: PropTypes.shape({
        $oid: PropTypes.string.isRequired
      }).isRequired
    }
  ),
  visit: PropTypes.shape(
    {
      _id: PropTypes.shape({
        $oid: PropTypes.string.isRequired
      }).isRequired
    }
  ),
  static: PropTypes.bool,
  value: PropTypes.string
};

export default injectUser(injectIntl(Comments));
