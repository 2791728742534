import { Roles, DefaultRoles } from '@markham/job-manager-role';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import bugsnagClient from "./bugsnag";

const defaultRoles = new Roles(DefaultRoles);

const UserContext = React.createContext({
  roles: defaultRoles
});

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

let globalUser,
  globalRoles;

// I really don't like this solution, but easiest to get out the door
// The modals used in this project detach from the parent context, so we no longer have access
// to our user and roles
export function setGlobalUser(user) {
  globalUser = user;
  const { _id: id, ...rest } = user;
  bugsnagClient.user = {
    id,
    ...rest
  };
}

export function setGlobalRoles(roles) {
  globalRoles = new Roles(roles);
}

export function injectUser(WrappedComponent, options = {}) {
  const {
    userPropName = 'user',
    rolesPropName = 'roles',
    withRef = false
  } = options;

  return class InjectUserComponent extends React.Component {

    static displayName = `InjectUser(${getDisplayName(WrappedComponent)})`;

    setReference = reference => {
      this.wrappedInstance = reference;
    };

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    render() {
      return (
        <UserContext.Consumer>
          {
            context => (
              <WrappedComponent
                {...this.props}
                {...{ [userPropName]: context.user || globalUser }}
                {...{ [rolesPropName]: context.roles || globalRoles || defaultRoles }}
                ref={withRef ? this.setReference : null}
              />
            )
          }
        </UserContext.Consumer>
      )
    }
  }
}

export class UserProvider extends React.Component {

  static getDerivedStateFromProps({ user, roles }) {
    return {
      user,
      roles: roles ? new Roles(roles) : defaultRoles
    };
  }

  state = {
    user: null,
    roles: defaultRoles
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }

}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.object,
  roles: PropTypes.object
};

export default UserProvider;

export function useRole(role, fn = "isOrChild") {
  const { user, roles } = useContext(UserContext);

  if (!user) {
    return false;
  }

  return useMemo(() => {
    return roles[fn](user.role, role);
  }, [roles, user.role, role]);
}
