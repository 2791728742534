import React from 'react';
import EditInvoice from './edit-invoice';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';

class EditInvoiceDialog extends React.PureComponent {

  isNonDefaultApplicator() {
    if (!(this.props.job && this.props.visit)) {
      return false;
    }

    if (!(this.props.job.applicator && this.props.job.applicator.$id && this.props.job.applicator.$id.$oid)) {
      return true; // Can never be the default
    }

    if (!(this.props.visit.applicator && this.props.visit.applicator.$id && this.props.visit.applicator.$id.$oid)) {
      return true; // No way to tell
    }

    return this.props.visit.applicator.$id.$oid !== this.props.job.applicator.$id.$oid;
  }

  render() {
    return (
      <Dialog
        title="Site Visit Invoice"
        actions={[
          <RaisedButton
            onClick={this.props.onRequestClose}
            label="Cancel"
          />,
          <RaisedButton
            onClick={this.props.onSave.bind(this, this.props.visit, this.props.job)}
            label="Save"
            primary
            disabled={!(!this.isNonDefaultApplicator() || (this.props.visit.invoice || {}).allocation)}
          />
        ]}
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
      >
        <EditInvoice onChange={this.props.onChange} visit={this.props.visit} job={this.props.job} />
      </Dialog>
    );
  }

}

EditInvoiceDialog.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  onSave: React.PropTypes.func.isRequired,
  onRequestClose: React.PropTypes.func.isRequired,
  open: React.PropTypes.bool.isRequired,
  visit: React.PropTypes.object,
  job: React.PropTypes.object,
  visits: React.PropTypes.array
};

export default EditInvoiceDialog;
