import { useState } from "react";
import { useEditableConfig, putEditable } from "./config";

function getKey(key) {
  return `ui:view-type:${key}`;
}

export function useViewTypeSetter(key, def = "standard") {
  const base = useViewType(key, def);
  // We want a way to make the view update instantly, so lets use this value which
  // will be undefined if we want to use the value from config
  const [intermediate, setIntermediate] = useState(undefined);
  const setViewTypeSync = (value) => {
    setIntermediate(value);
    setViewType(key, value)
      .then(() => {
        if (intermediate === value) {
          // Reset to undefined so different components can update this
          setIntermediate(undefined);
        }
      })
      .catch(error => {
        // TODO figure out what to do here
        console.warn(error);
        setIntermediate(undefined);
      });
  };
  return [
    intermediate || base,
    setViewTypeSync
  ];
}

export function useViewType(key, def = undefined) {
  const editable = useEditableConfig();
  return editable[getKey(key)] || def;
}

export async function setViewType(key, value) {
  if (typeof key !== "string") {
    throw new Error("Invalid key")
  }
  if (typeof value !== "string") {
    throw new Error("Invalid value")
  }
  return putEditable(
    getKey(key),
    value
  );
}
