import React, { useEffect, useCallback } from "react";
import Dialog from 'material-ui/Dialog';
import RaisedButton from "material-ui/RaisedButton";
import { ViewSiteVisitOutput } from "@markham/site-visit-web";
import { Tab, Tabs } from "material-ui/Tabs"

export function OutputModal(props) {
  const { isOpen, tasks, onRequestClose } = props;
  return (
    <Dialog
      contentClassName="site-visit-output-modal-content"
      bodyClassName="site-visit-output-modal-body"
      title="Site Visit Output"
      actions={[
        <RaisedButton
          onClick={onRequestClose}
          label="Close"
        />
      ]}
      open={isOpen}
      onRequestClose={onRequestClose}
    >
      {
        tasks.length === 1 ? (
          <ViewSiteVisitOutput task={tasks[0]} />
        ) : (
          <div className="tabs-container">
            <Tabs>
              {
                tasks.map(
                  (task, index) => (
                    <Tab key={index} label={task.assignedToDocument.name || task.assignedToDocument.username}>
                      <ViewSiteVisitOutput task={task} />
                    </Tab>
                  )
                )
              }
            </Tabs>
          </div>
        )
      }

    </Dialog>
  )
}
