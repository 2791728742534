import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import React, {useCallback, useMemo} from "react";
import {RaisedButton} from "material-ui";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "material-ui/svg-icons/action/info";
import Alert from "material-ui/svg-icons/alert/warning";
import { useTraceUpdate } from "./props";

function Applicator(props) {
  useTraceUpdate(props, "Applicator");
  const { noInfo, disabled, applicator, index, onApplicatorChange, availableApplicators, primaryLocationCode, applicatorIds, onRemoveApplicator } = props;
  const onChange = useCallback((event, child, value) => {
    onApplicatorChange(index, event, child, value);
  }, [onApplicatorChange, index]);

  const onRemove = useCallback(() => {
    onRemoveApplicator(index);
  }, [onRemoveApplicator, index]);

  const applicatorDocument = applicator && index > 0 && availableApplicators.find(({ _id: { $oid }}) => $oid === applicator.$oid);
  const isApplicatorDifferentLocation = applicatorDocument && applicatorDocument.locationCode && primaryLocationCode && applicatorDocument.locationCode !== primaryLocationCode;

  return (
    <div className="edit-applicator">
      <SelectField
        floatingLabelText={index === 0 ? "Primary Applicator" : "Additional Applicator"}
        hintText={index === 0 ? "Primary Applicator" : "Additional Applicator"}
        value={applicator ? applicator.$oid : undefined}
        onChange={onChange}
        disabled={disabled}
      >
        {
          availableApplicators
            .filter(({ locationCode }) => locationCode)
            .filter(({ _id: { $oid }, name, username}) => {
              if ((name || username).toUpperCase().includes("PM STATUS CHECK")) {
                return false;
              }
              if (applicator && applicator.$oid === $oid) {
                return true;
              }
              return applicatorIds.indexOf($oid) === -1;
            })
            .sort(({ name: a, username: aNext }, { name: b, username: bNext }) => (a || aNext) < (b || bNext) ? -1 : 1)
            .map(
              ({ name, username, _id }, index) => {
                return <MenuItem key={index} value={_id.$oid} primaryText={name || username} />
              }
            )
        }
      </SelectField>
      <div className="actions">
        {
          !noInfo && index === 0 ? (
            <Tooltip title={<span>The primary applicator will need to provide applied m<sup>2</sup>, litres of product used, and complete the markup of the area plan</span>}>
              <Info />
            </Tooltip>
          ) : (
            !noInfo && isApplicatorDifferentLocation ? (
              <Tooltip title="This applicator has a different location code to the primary applicator meaning they may need to provide litres of product used if they're using their own location's product.">
                <Alert />
              </Tooltip>
            ) : <div />
          )
        }
        {
          index > 0 && applicator ? (
            <RaisedButton
              label="Remove Applicator"
              onClick={onRemoveApplicator}
              disabled={disabled}
            />
          ) : undefined
        }
      </div>
    </div>
  )
}

export function EditApplicator(props) {
  useTraceUpdate(props, "EditApplicator");
  const { disabled, applicators, onChange, availableApplicators } = props;
  const onApplicatorChange = useCallback((index, event, child, value) => {
    let newApplicators = applicators.slice();
    newApplicators[index] = {
      $oid: value
    };
    onChange(
      // Filter duplicates
      newApplicators.filter(
        (value, index, array) => {
          const before = array.slice(0, index);
          const found = before.find(({ $oid }) => $oid === value.$oid);
          return !found;
        }
      )
    );
  }, [applicators, onChange]);
  const onRemoveApplicator = useCallback(index => {
    const newApplicators = applicators.slice();
    newApplicators.splice(index, 1);
    onChange(newApplicators);
  }, [applicators, onChange]);
  const applicatorIds = useMemo(() => applicators.map(({ $oid }) => $oid), [applicators]);
  const primaryLocationCode = useMemo(() => {
    if (!applicators[0]) {
      return undefined;
    }
    const primaryApplicator = availableApplicators.find(({ _id: { $oid }}) => $oid === applicators[0].$oid);
    if (!primaryApplicator) {
      return undefined;
    }
    return primaryApplicator.locationCode;
  }, [applicators, availableApplicators]);
  return (
    <div className="edit-applicators">
      {
        applicators.concat(undefined)
          .map(
            (applicator, index) => {
              return (
                <Applicator
                  key={index}
                  availableApplicators={availableApplicators}
                  applicator={applicator}
                  index={index}
                  onApplicatorChange={onApplicatorChange}
                  primaryLocationCode={primaryLocationCode}
                  applicatorIds={applicatorIds}
                  onRemoveApplicator={onRemoveApplicator}
                  disabled={disabled}
                  noInfo={props.noInfo}
                />
              )
            }
          )
      }
    </div>
  )
}
