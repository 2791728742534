.timeline {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.timeline-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.timeline-item:not(:last-child) .timeline-item-contents {
    border-bottom: 1px solid rgb(235, 235, 235);
}

.timeline-item-contents {
    min-height: 70px;
    padding: 15px 5px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

.timeline-item-contents h3, .timeline-item-contents p {
    margin: 0
}

.timeline-item-contents time, .timeline-item-contents time + span, .timeline-item-contents .authored {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.9em;
}

.timeline-item .timeline-item-icon {
    width: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
}

.timeline-item .timeline-item-icon svg {
    z-index: 3;
    width: 30px;
    height: 30px;
    fill: #282c34;
    color: #282c34;
    position: relative;
    box-sizing: border-box;
}

.timeline-item-icon .timeline-item-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: rgb(235, 235, 235);
    border-radius: 100%;
    z-index: 2;
    position: absolute;
    box-sizing: border-box;
}

.timeline-item .timeline-item-icon::before {
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    z-index: 1;
    content: " ";
    width: 2px;
    background-color: rgb(235, 235, 235);
    height: 100%;
}

.timeline-item .timeline-item-icon .reverse-icon svg {
    transform: rotateY(180deg);
    transform-origin: 50% 50%;
}

.timeline-item:only-child .timeline-item-icon::before {
    display: none;
}

.timeline-item:first-child .timeline-item-icon::before {
    top: 50%;
    height: 50%;
}

.timeline-item:last-child .timeline-item-icon::before {
    height: 50%;
}

.timeline .output {
    margin-top: 5px;
}

.timeline .area-list {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.timeline .area-list svg {
    height: 0.5em;
    width: 0.5em;
    margin: 0 3px;
    min-width: auto;
    min-height: auto;
}

.timeline table.output-table {
    border-collapse: collapse;
    width: 100%
}

.timeline table.output-table td, .timeline table.output-table th {
    line-height: 1.3;
    padding: 3px 0;
}

.timeline table.output-table th {
    padding-left: 0;
    text-align: left;
    font-weight: bold;
}

.timeline table.output-table td {
    padding-left: 10px;
    text-align: left;
}
.timeline table.output-table sup {
    font-size: 0.6em;
}

.timeline table.output-table tr:not(:last-child) td, .timeline table.output-table tr:not(:last-child) th {
    border-bottom: 1px solid rgb(235, 235, 235);
}
