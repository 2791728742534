export default {
  locale: 'en',
  messages: {
    app: {
      dropZone: {
        maxUpload: 'Maximum file size: {bytes}',
        uploadMessage: 'Drop image here to upload',
        uploadOrMessage: 'or',
        uploadSelectMessage: 'Select Image'
      },
      job: {
        commentDate: 'at {date}',
        commentDateFormat: 'HH:mm A dddd MMM DD, YYYY',
        dashboard: {
          commentDateFormat: 'DD/MM/YY'
        }
      }
    }
  }
};
