import React from 'react';
import PropTypes from 'prop-types';
import Callback from './callback';

class LoginScreen extends React.Component {

  componentDidMount() {
    return this.props.login();
  }

  render() {
    return <Callback />
  }

}

LoginScreen.propTypes = {
  login: PropTypes.func.isRequired
};

export default LoginScreen;
