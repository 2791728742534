const config = {
  envConfig: {
    url: {
      "jobmanager.markhamglobal.com": "productionJobManager",
      "ui.markham.cloud": "production",
      "dev-ui.markham.cloud": "development",
      "dev.jobmanager.markhamglobal.com": "development",
      localhost: "local",
      default: "local",
    },
  },
  env: {
    productionJobManager: {
      flags: {
        APPLICATOR_APP: true,
        REPORT_SERVICE: true,
      },
      storage: {
        prefix: "markham.cloud",
      },
      api: {
        url: "https://production.api.markham.cloud",
        handoverUrl: "https://sales.markham.cloud/handover",
        reportsUrl: "https://production.reports.api.markham.cloud",
        createReportFunction: "report-service-prod-create-report",
        createReportFunctionRegion: "us-east-1",
      },
      auth: {
        domain: "markham.au.auth0.com",
        clientID: "IxNnvDwqOVLmDFTNKUumlzgX5HzcvhkN",
        audience: "https://production.api.markham.cloud/",
        redirectUri: "https://jobmanager.markhamglobal.com/callback",
        responseType: "token id_token",
        scope: "openid profile email",
      },
      bugsnag: {
        apiKey: "c7b6a91ac2ed66d880d31798fd3101b3",
      },
    },
    production: {
      flags: {
        APPLICATOR_APP: true,
        REPORT_SERVICE: true,
      },
      storage: {
        prefix: "markham.cloud",
      },
      api: {
        url: "https://production.api.markham.cloud",
        handoverUrl: "https://sales.markham.cloud/handover",
        reportsUrl: "https://production.reports.api.markham.cloud",
        createReportFunction: "report-service-prod-create-report",
        createReportFunctionRegion: "us-east-1",
      },
      auth: {
        domain: "markham.au.auth0.com",
        clientID: "IxNnvDwqOVLmDFTNKUumlzgX5HzcvhkN",
        audience: "https://production.api.markham.cloud/",
        redirectUri: "https://ui.markham.cloud/callback",
        responseType: "token id_token",
        scope: "openid profile email",
      },
      bugsnag: {
        apiKey: "c7b6a91ac2ed66d880d31798fd3101b3",
      },
    },
    development: {
      flags: {
        APPLICATOR_APP: true,
        REPORT_SERVICE: true,
      },
      storage: {
        prefix: "dev.markham.cloud",
      },
      api: {
        url: "https://development.api.markham.cloud",
        handoverUrl: "https://dev-sales.markham.cloud/handover",
        reportsUrl: "https://development.reports.api.markham.cloud",
        createReportFunction: "report-service-dev-create-report",
        createReportFunctionRegion: "us-east-1",
      },
      auth: {
        domain: "markham-development.au.auth0.com",
        clientID: "EU2Sk9ZTux16o0SnTAemwwx403ee6DX1",
        audience: "https://development.api.markham.cloud/",
        redirectUri: "https://dev-ui.markham.cloud/callback",
        responseType: "token id_token",
        scope: "openid profile email",
      },
      bugsnag: {
        apiKey: "c7b6a91ac2ed66d880d31798fd3101b3",
      },
    },
    local: {
      flags: {
        APPLICATOR_APP: true,
        REPORT_SERVICE: true,
      },
      storage: {
        prefix: "local.markham.cloud",
      },
      api: {
        url: "https://development.api.markham.cloud",
        //url: "http://localhost:8088",
        handoverUrl: "https://dev-sales.markham.cloud/handover",
        reportsUrl: "https://development.reports.api.markham.cloud",
        createReportFunction: "report-service-dev-create-report",
        createReportFunctionRegion: "us-east-1",
      },
      auth: {
        domain: "markham-development.au.auth0.com",
        clientID: "EU2Sk9ZTux16o0SnTAemwwx403ee6DX1",
        audience: "https://development.api.markham.cloud/",
        redirectUri: "http://localhost:3000/callback",
        responseType: "token id_token",
        scope: "openid profile email",
      },
      bugsnag: {
        apiKey: "abc",
      },
    },
  },
};

export function getEnvironment() {
  if (typeof location === "undefined") {
    return config.envConfig.url.default;
  }
  return (
    config.envConfig.url[location.hostname] || config.envConfig.url.default
  );
}

/**
 * @returns {Config}
 */
function generateConfig() {
  const env = getEnvironment();
  return config.env[env];
}

export default generateConfig();

/**
 * @typedef {object} Config
 * @property {ApiConfig} api
 * @property {AuthenticationConfig} auth
 * @property {BugsnagConfig} bugsnag
 * @property {StorageConfig} storage
 */

/**
 * @typedef {object} ApiConfig
 * @property {string} url
 * @property {string} handoverUrl
 */

/**
 * @typedef {object} AuthenticationConfig
 * @property {string} domain
 * @property {string} clientID
 * @property {string} redirectUri
 * @property {string} responseType
 * @property {string} scope
 */

/**
 * @typedef {object} BugsnagConfig
 * @property {string} apiKey
 */

/**
 * @typedef {object} StorageConfig
 * @property {string} prefix
 */
