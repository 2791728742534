import IconButton from "material-ui/IconButton";
import React from "react";
import { fetch } from '../../../../../services/fetch';

export default ({ id, name, initialValue, iconButtonTooltipStyle, component: Component, url }) => {
  const [value, setValue] = React.useState(initialValue);

  async function triggerAsync() {
    const response = await fetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json+bson'
        },
        body: JSON.stringify({
          id,
          value: !value
        }),
        credentials: 'include'
      }
    );
    const result = await response.json();
    setValue(typeof result.value === 'boolean' ? result.value : value);
  }

  function trigger() {
    triggerAsync()
      .catch(console.error);
  }

  return (
    <div className={name.toLowerCase()}>
      <IconButton
        title={name}
        alt={name}
        tooltipPosition="bottom-center"
        style={{width:32, height:32}}
        iconStyle={{width:32, height:32}}
        tooltipStyles={iconButtonTooltipStyle}
        onClick={trigger}
      >
        <Component height="18" width="18" color={value ? "blue" : null}/>
      </IconButton>
    </div>
  );
}
