import React from 'react';
import TextField from 'material-ui/TextField';

class EditArea extends React.PureComponent {

  getPopulatedVisit(propVisit = this.props.visit) {
    const visit = Object.assign({}, propVisit, {
      area: this.props.visit.area || []
    });

    if (!(this.props.job && this.props.job.products)) {
      return visit;
    }

    if (visit.area.length < this.props.job.products.length) {
      visit.area = this.props.job.products
        .map((product, index) => {
          return visit.area[index] || {
            value: ''
          };
        });
    }

    return visit;
  }

  onUpdateFieldIndex(index, field, event) {
    const visit = this.getPopulatedVisit(this.props.visit);

    visit.area[index] = Object.assign({}, visit.area[index] || {}, {
      [field]: event.target.value
    });

    this.props.onChange(visit, this.props.job);
  }

  render() {

    const visit = this.getPopulatedVisit(this.props.visit);

    const productComponents = (this.props.job.products || []).map((product, index) => {

      const errorStyle = {
        color: 'red'
      };

      let errorText = '';

      if ((visit.area[index].value || '').toString().trim().length && isNaN(+visit.area[index].value)) {
        errorText = 'A number is required';
      }

      return (
        <div className="product" key={index}>
          <TextField
            name={`product_area_${index}`}
            floatingLabelText={<span>Area for {product.productOther || product.product} m<sup>2</sup></span>}
            value={visit.area[index].value}
            onChange={this.onUpdateFieldIndex.bind(this, index, 'value')}
            errorStyle={errorStyle}
            errorText={errorText}
          />
        </div>
      );
    });

    return (
      <div className="visit-edit-area">
        {productComponents}
      </div>
    );
  }

}

EditArea.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  visit: React.PropTypes.object,
  job: React.PropTypes.object
};

export default EditArea;
