const visit = {
  Available: {
    description: "Applicator indicated they are available for the task",
    short: "Accepted job"
  },
  Unavailable: {
    description: "Applicator indicated they are not available for the task",
    short: "Requested Reschedule"
  },
  TravellingTo: {
    description: "Travelling to location",
    short: "Travelling to location"
  },
  TravelledTo: {
    description: "Travelled to location",
    short: "Travelled to location",
  },
  GainedEntry: {
    description: "Gained entry to site",
    short: "Gained entry to site",
  },
  CouldNotGainEntry: {
    description: "Could not gain entry to site",
    short: "No site access"
  },
  Induction: {
    description: "Completed Induction",
    short: "Inducted"
  },
  ConfirmArea: {
    description: "The applicator will confirm the area and compare the area to the plan.",
    short: "Confirmed area"
  },
  AreaUnusable: {
    description: "Area not ready for application",
    short: "Area not ready for application",
    issueWithAreaNotResolvableByApplicator: "Applicator could not resolve issue",
    issueWithAreaNotResolvableBySite: "Site could not resolve issue"
  },
  Application: {
    description: "Record application",
    short: "Application"
  },
  Break: {
    description: "Taking a break",
    short: "Started break"
  },
  LeftSite: {
    description: "Leaving the site",
    short: "Left site"
  },
  LeavingSite: {
    description: "Leaving the site",
    short: "Left site"
  },
  UpdatedProduct: {
    description: "New product variation",
    short: "Variation"
  },
  ApplicationComplete: {
    description: "Work is completed",
    short: "All Done"
  },
  TravellingFrom: {
    description: "Applicator left the site",
    short: "Left site"
  },
  TravelledFrom: {
    description: "Applicator left the site",
    short: "Travelled from site"
  },
  TravellingToNextSite: {
    description: "Applicator travelling to another site visit",
    short: "Travelling to next job"
  },
  Finished: {
    description: "Site visit finished",
    short: "Finished"
  },
  PrepareEquipment: {
    description: "Applicator prepared equipment for application",
    short: "Prepared equipment"
  },
  Rescheduled: {
    description: "Site visit was rescheduled",
    short: "Rescheduled"
  }
}

export const en = {
  "@markham/site-visit-web": {
    visit,
    comment: {
      authorApplicator: "Applicator",
      authorPM: "Markham"
    }
  },
  translation: {
    key: "value"
  }
};

