import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Helmet from 'react-helmet';
import Header from './components/header';

export class App extends Component {

  componentDidMount() {

  }

  render() {
    return (
      <div id="page">
        <Helmet
          title="Markham Global Job Manager"
          titleTemplate="%s - Markham Global"
          meta={[
            { charset: 'utf-8' },
            {
              'http-equiv': 'X-UA-Compatible',
              content: 'IE=edge'
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1'
            }
          ]}
        />
        <Header />
        <div className="page-wrapper">
          <section className="main-section">
            <main className="page-content">
              {this.props.children}
            </main>
          </section>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired
};

export default App;
