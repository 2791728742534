import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import CustomIcons from "../../../../../components/icons/customIcons";

export default ({ isAdmin, isAccounts, isViewJob, job, visit, iconButtonStyle, iconButtonSVGStyle, iconButtonTooltipStyle, duplicateVisit, addComment, addStaticComment, onUpdateComments }) => {
  return (
    <div className="visit-toolbar" onClick={(event) => event.stopPropagation()}>
      {
        useMemo(() => !isViewJob && job ? (
          <Link to={`/job/${job._id.$oid}`}>
            <IconButton
              title="Open Job"
              alt="Open Job"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={iconButtonSVGStyle}
              tooltipStyles={iconButtonTooltipStyle}
            >
              <CustomIcons height="18" width="18" icon='launch' />

            </IconButton>
          </Link>
        ) : null, [isViewJob, job ? job._id.$oid : undefined, iconButtonStyle, iconButtonSVGStyle, iconButtonTooltipStyle])
      }
      <Link to={`/job/visit/${visit._id.$oid}`}>
        <IconButton
          title="View Visit"
          alt="View Visit"
          tooltipPosition="bottom-center"
          style={iconButtonStyle}
          iconStyle={iconButtonSVGStyle}
          tooltipStyles={iconButtonTooltipStyle}
        >
          <CustomIcons height="20" width="20" icon='van' />
        </IconButton>
      </Link>
      {
        useMemo(() => addComment && onUpdateComments ? (
          <IconButton
            title="Comment"
            alt="Comment"
            tooltipPosition="bottom-center"
            style={iconButtonStyle}
            iconStyle={iconButtonSVGStyle}
            tooltipStyles={iconButtonTooltipStyle}
            onClick={addComment}
          >
            <CustomIcons height="20" width="20" icon='chat' />
          </IconButton>
        ) : undefined, [addComment, onUpdateComments])
      }
      {
        useMemo(() => (isAdmin || isAccounts) && addStaticComment && onUpdateComments ? (
          <IconButton
            title="Static Comment"
            alt="Static Comment"
            tooltipPosition="bottom-center"
            style={iconButtonStyle}
            iconStyle={iconButtonSVGStyle}
            tooltipStyles={iconButtonTooltipStyle}
            onClick={addStaticComment}
          >
            <CustomIcons height="20" width="20" icon='chat-minus' />
          </IconButton>
        ) : undefined, [isAdmin, isAccounts, addStaticComment, onUpdateComments])
      }
      {
        useMemo(() => isAdmin && isViewJob ? (
          <IconButton
            title="Create Another Site Visit"
            alt="Create Another Site Visit"
            tooltipPosition="bottom-center"
            style={iconButtonStyle}
            iconStyle={iconButtonSVGStyle}
            tooltipStyles={iconButtonTooltipStyle}
            onClick={duplicateVisit}
          >
            <CustomIcons height="20" width="20" icon='duplicate' />
          </IconButton>
        ) : null, [isViewJob, isAdmin, iconButtonStyle, iconButtonSVGStyle, iconButtonTooltipStyle, duplicateVisit])
      }
    </div>
  )
}
