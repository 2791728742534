import React from 'react';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class JobHealthRow extends React.PureComponent {

  onHealthValueUpdated(healthSubmission, field, event) {
    const newHealthSubmission = Object.assign({}, healthSubmission, {
      [field]: event.target.value
    });

    this.props.onChange(newHealthSubmission);
  }

  render() {
    const healthSubmission = this.props.health || {};

    return (
      <div className="health-row">
        <TextField
          disabled={this.props.disabled}
          className="flex-it"
          floatingLabelText="Hazard"
          name="hazard"
          value={healthSubmission.hazard || ''}
          onChange={this.onHealthValueUpdated.bind(this, healthSubmission, 'hazard')}
        />
        <SelectField
          disabled={this.props.disabled}
          className="big-label"
          floatingLabelText="Initial Risk"
          value={healthSubmission.risk || 'M'}
          onChange={(_, i, v) => this.onHealthValueUpdated(healthSubmission, 'risk', { target: { value: v } })}
        >
          <MenuItem value="C" primaryText="C" />
          <MenuItem value="H" primaryText="H" />
          <MenuItem value="M" primaryText="M" />
          <MenuItem value="L" primaryText="L" />
          <MenuItem value="VL" primaryText="VL" />
        </SelectField>
        <TextField
          disabled={this.props.disabled}
          className="flex-it"
          floatingLabelText="Controls"
          name="controls"
          value={healthSubmission.controls || ''}
          onChange={this.onHealthValueUpdated.bind(this, healthSubmission, 'controls')}
        />
        <SelectField
          disabled={this.props.disabled}
          className="big-label"
          floatingLabelText="Level of control"
          value={healthSubmission.type || 'E'}
          onChange={(_, i, v) => this.onHealthValueUpdated(healthSubmission, 'type', { target: { value: v } })}
        >
          <MenuItem value="E" primaryText="E" />
          <MenuItem value="M" primaryText="M" />
        </SelectField>
        <SelectField
          disabled={this.props.disabled}
          className="big-label"
          floatingLabelText="Residual Risk"
          value={healthSubmission.residualRisk || 'M'}
          onChange={(_, i, v) => this.onHealthValueUpdated(healthSubmission, 'residualRisk', { target: { value: v } })}
        >
          <MenuItem value="L" primaryText="L" />
          <MenuItem value="M" primaryText="M" />
          <MenuItem value="H" primaryText="H" />
        </SelectField>
        <Checkbox
          disabled={this.props.disabled}
          className="checkbox-wrapper"
          label="Discussion"
          name="discussion"
          checked={healthSubmission.discussion || false}
          onCheck={(_, v) => this.onHealthValueUpdated(healthSubmission, 'discussion', { target: { value: v } })}
        />
      </div>
    );
  }
}

JobHealthRow.propTypes = {
  health: React.PropTypes.object,
  disabled: React.PropTypes.bool,
  onChange: React.PropTypes.func.isRequired
};

export default JobHealthRow;
