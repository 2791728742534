import Storage from 'local-storage-fallback';
import Config from './static-config';

function getKey(key) {
  return `${Config.storage.prefix}.${key}`;
}

export function getItem(key) {
  return Storage.getItem(getKey(key));
}

export function setItem(key, value) {
  return Storage.setItem(getKey(key), value);
}

export function removeItem(key) {
  return Storage.removeItem(getKey(key));
}

export function clear() {
  return Storage.clear();
}

export default {
  getItem,
  setItem,
  removeItem,
  clear
};

