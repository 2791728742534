/**
 * Client entry point
 */
import 'babel-polyfill';
import './correct-react';
import './jss';
import React from 'react';
import { render } from 'react-dom';
import App from './app';
import History from './services/history';
import Authentication from './services/authentication';
import { Route, Router, Switch } from 'react-router-dom';
import Login from './modules/app/login';
import Logout from './modules/app/logout';
import Callback from './modules/app/callback';
import Modal from 'react-modal';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import bugsnagClient from "./services/bugsnag";

const mountApp = document.getElementById('root');

Modal.setAppElement(mountApp);

class BaseApp extends React.Component {

  authentication = Authentication;

  handleAuthentication = nextState => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      this.authentication.handleAuthentication();
    } else {
      console.log('handleAuthentication invoked with no access token in hash')
    }
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={History}>
          <Switch>
            <Route
              path="/callback"
              render={(props) => {
                this.handleAuthentication(props);
                return <Callback {...props} />
              }}
            />
            <Route
              path="/logout"
              render={(props) => {
                return <Logout {...props} logout={this.authentication.logout} />
              }}
            />
            <Route
              path="/"
              render={(props) => {
                if (this.authentication.isAuthenticated()) {
                  return <App {...props} authentication={this.authentication} />
                }
                return <Login {...props} login={this.authentication.login} />
              }}
            />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    )
  }

}

const ErrorBoundary = bugsnagClient.getPlugin('react');

render(
  <ErrorBoundary>
    <BaseApp />
  </ErrorBoundary>,
  mountApp
);
