import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import { Link } from 'react-router-dom';
import Visit from '../../job/components/visit';
import { injectUser } from '../../../services/user';
import { fetch } from '../../../services/fetch';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { putEditable } from '../../../services/config';

class Sidebar extends React.PureComponent {

  state = {};

  static propTypes = {
    visits: React.PropTypes.arrayOf(
      React.PropTypes.shape({
        job: React.PropTypes.shape({
          $id: React.PropTypes.shape({
            $oid: React.PropTypes.string
          })
        })
      })
    ),
    jobs: React.PropTypes.arrayOf(
      React.PropTypes.shape({
        _id: React.PropTypes.shape({
          $oid: React.PropTypes.string
        })
      })
    ),
    onScheduleVisit: React.PropTypes.func.isRequired,
    onNewVisit: React.PropTypes.func.isRequired,
    onAssignVisit: React.PropTypes.func.isRequired,
    applicators: React.PropTypes.array.isRequired,
    isDraggable: React.PropTypes.bool,
    user: React.PropTypes.object,
    roles: React.PropTypes.object,
    showScheduled: React.PropTypes.bool
  };

  getUnscheduledVisits() {
    return this.props.visits
      .filter((visit) => !visit.date);
  }

  getJobForVisit(visit) {
    return this.props.jobs
      .find((job) => job._id.$oid === visit.job.$id.$oid);
  }

  onHide = async () => {
    try {
      await new Promise(resolve => this.setState({
        savingHide: true
      }, resolve));
      await putEditable('dashboard:hide-sidebar', true);
    } catch(e) {
      console.warn(e);
      alert('Unable to hide sidebar');
      await new Promise(resolve => this.setState({
        savingHide: false
      }, resolve));
    }
  };

  render() {
    const visits = this.getUnscheduledVisits(),
      isSales = this.props.roles.is(this.props.user.role, 'sales'),
      isAccounts = this.props.roles.is(this.props.user.role, 'accounts');

    return (
      <div className="sidebar">
        <div className="sidebar-actions">
          <div className="sidebar-header-with-hide">
            <h3>Unscheduled<span className="hide-on-2">&nbsp;Jobs</span></h3>
            &nbsp;&nbsp;
            <FlatButton secondary onClick={this.onHide} disabled={this.state.savingHide}>
              Hide
            </FlatButton>
          </div>
          {!(isSales || isAccounts) ? (
            <Link to="/job/create">
              <RaisedButton
                primary
                className="white-text-icon-button"
              >
                <span className="label-wrapper">
                  <span className="visible-xs visible-sm visible-md visible-lg"><ContentAdd /></span>

                </span>
              </RaisedButton>
            </Link>
          ) : ''}
        </div>
        <div className="sidebar-contents">
          {
            visits.map((visit, index) => (
              <Visit
                sidebar={true}
                key={`calendar-sidebar-${index}-${visit._id.$oid}`}
                visit={visit}
                job={this.getJobForVisit(visit)}
                onScheduleVisit={this.props.onScheduleVisit}
                onNewVisit={this.props.onNewVisit}
                onAssignVisit={this.props.onAssignVisit}
                applicators={this.props.applicators}
                isDraggable={this.props.isDraggable}
                showScheduled={this.props.showScheduled}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

export default injectUser(Sidebar);
