import { resources } from "./translations";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    // The default language
    fallbackLng: "en",

    // The allowed languages
    whitelist: [
      "en"
    ],

    lng: "en",

    resources,

    // Namespaces to load
    ns: [
      "translation",
      "@markham/site-visit-web"
    ],

    defaultNS: "translation",

    // Logs info level to console output. Helps finding issues with loading not working.
    debug: true,

    // cache: {
    //   enabled: true
    // },

    interpolation: {
      // Not needed for react as it does escape per default to prevent xss
      escapeValue: false
    },

    react: {
      wait: true
    }
  })
  .catch(console.error)
