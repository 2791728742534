import React, { useMemo, Fragment } from "react";

export default ({ viewsEnabled, sidebar, applicator, job, visit, time, onFilterJob }) => {
  const onFilter = useMemo(() => {
    if (!onFilterJob || !job) {
      return undefined;
    }
    return event => {
      event.preventDefault();
      event.stopPropagation();
      onFilterJob(job.number);
    }
  }, [onFilterJob, job && job.number]);
  const stopPropagation = useMemo(() => {
    if (!onFilterJob) return undefined;
    return event => {
      event.preventDefault();
      event.stopPropagation();
    };
  }, [onFilterJob]);
  return useMemo(() => {
    let jobNumber = job ? job.number.trim() : 'NA';
    if (viewsEnabled.reducedJobNumber) {
      jobNumber = jobNumber.replace(/^([a-z\d+-_]+).*/i, "$1");
    }

    if (onFilter) {
      jobNumber = <a href="#" onClick={onFilter}>{jobNumber}</a>
    }

    const applicatorName = ((applicator && (applicator.name || applicator.username)) ? applicator.name || applicator.username : null) || 'No Applicator Assigned';

    const visitString = `Visit: ${visit.sequence}`;

    if (sidebar) {
      return (
        <Fragment>
          {jobNumber} - {applicatorName}<br/>
          {visitString}
          {visit.staticComment ? <Fragment><br/>{visit.staticComment}</Fragment> : undefined}
        </Fragment>
      );
    }
    return (
      <div className="visit-applicator-name" onClick={stopPropagation}>
        <div>{applicatorName}</div>
        <div className="job-number">{jobNumber}{time ? ` - ${time}` : ''}</div>
        <div className="job-number">{visitString}</div>
        {
          visit.staticComment ? <div className="job-number">{visit.staticComment}</div> : undefined
        }
      </div>
    );
  }, [stopPropagation, onFilter, visit.staticComment, viewsEnabled.reducedJobNumber, sidebar, job && job.number, applicator && (applicator.name || applicator.username), visit.sequence, time]);
};
