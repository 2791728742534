import PropTypes from 'prop-types';
import React from 'react';
import { fetch } from './fetch';
import Mitt from 'mitt';
import isPlainObject from 'is-plain-object';
import bugsnagClient from "./bugsnag";

export const EditableConfigChangeEmitter = Mitt();

export const ConfigContext = React.createContext({ config: {} });

let globalConfig;

// I really don't like this solution, but easiest to get out the door
// The modals used in this project detach from the parent context, so we no longer have access
// to our config
export function setGlobalConfig(config) {
  globalConfig = config;
  bugsnagClient.metaData = bugsnagClient.metaData || {};
  bugsnagClient.metaData.countryCode = config.country_code;
}

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export function injectConfig(WrappedComponent, options = {}) {
  const {
    configPropName = 'config',
    withRef = false
  } = options;

  return class InjectConfigComponent extends React.Component {

    static displayName = `InjectConfig(${getDisplayName(WrappedComponent)})`;

    setReference = reference => {
      this.wrappedInstance = reference;
    };

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    render() {
      return (
        <ConfigContext.Consumer>
          {
            context => (
              <WrappedComponent
                {...this.props}
                {...{ [configPropName]: context.config || globalConfig }}
                ref={withRef ? this.setReference : null}
              />
            )
          }
        </ConfigContext.Consumer>
      )
    }
  }
}

export class ConfigProvider extends React.Component {

  state = {
    editable: {}
  };

  componentDidMount() {
    EditableConfigChangeEmitter.on('change', this.handleChange);
  }

  componentWillUnmount() {
    EditableConfigChangeEmitter.off('change', this.handleChange);
  }

  handleChange = editable => this.setState({
    editable
  });

  render() {
    return (
      <ConfigContext.Provider
        value={
          {
            config: {
              ...this.props.config,
              editable: {
                ...(this.props.config.editable || {}),
                ...(this.state.editable || {})
              }
            }
          }
        }
      >
        {this.props.children}
      </ConfigContext.Provider>
    )
  }

}

ConfigProvider.propTypes = {
  children: PropTypes.element.isRequired,
  config: PropTypes.object
};

export default ConfigProvider;

export async function putEditableObject(object) {
  if (!isPlainObject(object)) {
    throw new Error('Expected provided value to be a plain object');
  }
  const response = await fetch(
    '/config/putUserEditableConfig',
    {
      method: 'PUT',
      body: JSON.stringify(object),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response.ok) {
    throw new Error('Unable to save config');
  }
  globalConfig = {
    ...globalConfig,
    editable: {
      ...(globalConfig.editable || {}),
      ...object
    }
  };
  // We will use an emitter so we can get back into react land easily
  EditableConfigChangeEmitter.emit('change', globalConfig.editable);
}

export async function putEditable(key, value) {
  return putEditableObject({
    [key]: value
  })
}

export function useEditableConfig() {
  const { config } = React.useContext(ConfigContext);
  return (config || {}).editable || {};
}
