import './main.scss';

import React from 'react';
import { Router } from 'react-router';
import history from './services/history'
import { IntlProvider } from 'react-intl';
import { localizationData } from './i18n/legacy/setup';
import './i18n'
import MuiLegacyThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Theme from './theme';
import routes from './routes';
import UserProvider, { setGlobalUser, setGlobalRoles } from './services/user';
import ConfigProvider, { setGlobalConfig } from './services/config';
import Callback from './modules/app/callback';
import { fetch } from './services/fetch';

class App extends React.Component {

  state = {
    config: null,
    userInfo: null
  };

  componentDidMount() {
    Promise.all([
      this.user(),
      this.config()
    ])
      .catch(console.error);
  }

  async user() {
    const response = await fetch('/user/fetchUserInfo');
    const json = await response.json();
    setGlobalUser(json.user);
    setGlobalRoles(json.roles);
    this.setState({
      userInfo: json
    });
  }

  async config() {
    const response = await fetch('/config/fetchUserConfig');
    const json = await response.json();
    setGlobalConfig(json);
    this.setState({
      config: json
    });
  }

  render() {
    if (!(this.state.config && this.state.userInfo)) {
      return <Callback />
    }

    return (
      <UserProvider {...this.state.userInfo}>
        <ConfigProvider config={this.state.config}>
          <MuiLegacyThemeProvider muiTheme={Theme}>
            <IntlProvider {...localizationData.en}>
              <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                {routes()}
              </Router>
            </IntlProvider>
          </MuiLegacyThemeProvider>
        </ConfigProvider>
      </UserProvider>
    );
  }

}

export default App;
