import React  from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class CallbackScreen extends React.Component {

  render() {
    const style = {
      display: 'flex',
      minHeight: '100vh',
      minWidth: '100vw',
      alignItems: 'center',
      justifyContent: 'center'
    };
    return (
      <div style={style}>
        <CircularProgress />
      </div>
    );
  }

}

export default CallbackScreen;
