import React from "react";
import TextField from "material-ui/TextField";
import Map from "./map";
import loadMapScripts from "./google-api-map-loader";
import SearchAddressTextField from "./search-address-field";
import MenuItem from "material-ui/MenuItem";
import SocialPerson from "material-ui/svg-icons/social/person";
import DropDownMenu from "material-ui/DropDownMenu";
import EditJobProduct from "./edit-job-product";
import { injectUser } from "../../../services/user";
import { injectConfig } from "../../../services/config";
import HealthRow from "./health-row";
import { orange300 } from "material-ui/styles/colors";
import { fetch } from "../../../services/fetch";
import SelectField from "material-ui/SelectField";
import { Divider, FlatButton } from "material-ui";
import { Button } from "@material-ui/core";

class EditJob extends React.PureComponent {
  static componentForm = {
    street_number: "short_name",
    route: "long_name",
    sublocality_level_1: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
  };

  static propTypes = {
    job: React.PropTypes.object.isRequired,
    onJob: React.PropTypes.func.isRequired,
    isScriptLoadSucceed: React.PropTypes.bool,
    isScriptLoaded: React.PropTypes.bool,
    onIsValid: React.PropTypes.func,
    user: React.PropTypes.object,
    roles: React.PropTypes.object,
    config: React.PropTypes.object,
  };

  state = {
    job: {},
    marker: null,
    map: null,
    applicators: [],
  };

  constructor(props, ...args) {
    super(props, ...args);

    this.state.job = this.assignJobDefaults(props.job);
  }

  componentDidMount() {
    this.triggerIsValid(this.state.job);
    return this.fetchApplicators();
  }

  async fetchApplicators() {
    return this.fetchApplicatorsClient();
  }

  async fetchApplicatorsClient() {
    const response = await fetch("/job/getApplicators", {
      credentials: "include",
    });

    const applicators = await response.json();

    return new Promise((resolve) =>
      this.setState(
        {
          applicators,
        },
        resolve
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.job !== this.props.job) {
      this.setJob(nextProps.job);
    }
    this.triggerIsValid(nextProps.job);
  }

  assignJobDefaults(originalJob) {
    const job = Object.assign({}, originalJob);

    job.locationCode = job.locationCode || "";

    job.number = job.number || "";
    job.site = Object.assign({}, job.site || {});
    job.site.name = job.site.name || "";
    job.site.address1 = job.site.address1 || "";
    job.site.address2 = job.site.address2 || "";
    job.site.city = job.site.city || "";
    job.site.region = job.site.region || "";
    job.site.postcode = job.site.postcode || "";
    job.contact = Object.assign({}, job.contact || {});
    job.contact.name = job.contact.name || "";
    job.contact.phone = job.contact.phone || "";
    job.contact.email = job.contact.email || "";
    job.contact.engineer_name = job.contact.engineer_name || "";
    job.contact.engineer_phone = job.contact.engineer_phone || "";
    job.contact.engineer_email = job.contact.engineer_email || "";
    job.contact.architect_name = job.contact.architect_name || "";
    job.contact.architect_phone = job.contact.architect_phone || "";
    job.contact.architect_email = job.contact.architect_email || "";
    job.contact.builder_name = job.contact.builder_name || "";
    job.contact.builder_phone = job.contact.builder_phone || "";
    job.contact.builder_email = job.contact.builder_email || "";
    job.comments = job.comments || "";
    job.applicator = job.applicator || null;
    job.position = job.position || {};
    job.products = (job.products || []).slice();
    job.sitevisits = Object.assign({}, job.sitevisits || {});

    job.health = (job.health || []).slice();

    if (
      job.health.length === 0 &&
      !job._id &&
      !job.health_default_assigned &&
      Array.isArray(
        (((this.props.config || {}).country_config || {}).job || {}).healthRows
      )
    ) {
      job.health_default_assigned = true;
      job.health = this.props.config.country_config.job.healthRows.slice();
    }

    job.products = job.products.map((product) => {
      if (product.area || product.charge_rate) {
        return product;
      }
      return Object.assign({}, product, {
        area: "",
        charge_rate: "",
      });
    });

    const empty = job.products.find(
      (product) => !product.area && !product.charge_rate
    );

    if (!empty) {
      job.products.push({ area: "", charge_rate: "" });
    }

    const emptyHealth = job.health.find(
      (health) => !(health.hazard || health.method)
    );

    if (!emptyHealth) {
      job.health.push({
        type: "E",
      });
    }

    return job;
  }

  triggerIsValid(job) {
    const isAdminOrAccounts = this.props.roles.isOrChild(
      this.props.user.role,
      "accounts"
    );
    const defaultsJob = this.assignJobDefaults(job);
    const valid =
      defaultsJob &&
      defaultsJob.locationCode &&
      defaultsJob.number &&
      defaultsJob.site &&
      defaultsJob.site.name &&
      defaultsJob.site.address1 &&
      defaultsJob.contact &&
      (defaultsJob.contact.phone || defaultsJob.contact.email) &&
      defaultsJob.products &&
      defaultsJob.products.length &&
      !defaultsJob.products.find(
        (product) =>
          (product.area && isNaN(+product.area)) ||
          (product.area && !product.product) ||
          (product.product === "Other" && !product.productOther) ||
          (isAdminOrAccounts &&
            product.charge_rate &&
            isNaN(+product.charge_rate))
      );

    if (this.props.onIsValid instanceof Function) {
      this.props.onIsValid(valid);
    }
  }

  setJob(job) {
    this.triggerIsValid(job);
    this.setState({
      job: this.assignJobDefaults(job),
    });
  }

  onLocationCode = (event, unused, value) => {
    const job = Object.assign({}, this.state.job, {
      locationCode: value,
    });
    this.setJob(job);
    this.props.onJob(job);
  };

  onUpdateTextField(field, event) {
    const job = Object.assign({}, this.state.job, {
      [field]: event.target.value,
    });
    this.setJob(job);
    this.props.onJob(job);
  }

  onUpdateSiteTextField(field, event) {
    let value = event.target.value;

    if (typeof value === "undefined" && event.target.textContent) {
      value = event.target.textContent;
    }

    let jobData = this.state.job;

    if (field == "name") {
      jobData = Object.assign({}, jobData, {
        name: value,
      });
    }

    const job = Object.assign({}, jobData, {
      site: Object.assign({}, jobData.site, {
        [field]: value,
      }),
    });

    this.setJob(job);
    this.props.onJob(job);
  }

  onUpdateContactTextField(field, event) {
    let job = false;
    if (field.indexOf("job_contact_") < 0) {
      job = Object.assign({}, this.state.job, {
        contact: Object.assign({}, this.state.job.contact, {
          [field]: event.target.value,
        }),
      });
    } else {
      let index = Number.parseInt(field.split("_").pop());

      const key = field.split("_")[2];
      const contacts = this.getContacts();
      const alts = [];

      if (index === 0) {
        job = Object.assign({}, this.state.job, {
          contact: Object.assign({}, this.state.job.contact, {
            [key]: event.target.value,
          }),
        });
      } else {
        contacts.map((c, i) => {
          if (i == 0) {
            return;
          }

          if (i === index) {
            c[key] = event.target.value;
          }

          const alt = {
            name: c.name,
            phone: c.phone,
            email: c.email,
          };

          alts.push(alt);
        });

        job = Object.assign({}, this.state.job, {
          contact: Object.assign({}, this.state.job.contact, {
            alts: alts,
          }),
        });
      }
    }

    this.setJob(job);
    this.props.onJob(job);
  }

  onPlace(place) {
    const components = {};

    (place.address_components || []).forEach(function mapComponent(component) {
      const addressType = component.types[0];
      if (!EditJob.componentForm[addressType]) {
        return;
      }
      components[addressType] = component[EditJob.componentForm[addressType]];
    });

    const site = Object.assign({}, this.state.job.site, {
      address1: [components.street_number || "", components.route || ""]
        .join(" ")
        .trim(),
      address2: (components.sublocality_level_1 || "").trim(),
      city: (components.locality || "").trim(),
      region: (components.administrative_area_level_1 || "").trim(),
      postcode: (components.postal_code || "").trim(),
    });

    const job = Object.assign({}, this.state.job, {
      site,
      position: {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      },
    });

    this.setState({
      job,
      marker: {
        position: place.geometry.location,
        defaultAnimation: 2,
      },
    });

    this.props.onJob(job);
  }

  onMapClick(event) {
    const job = Object.assign({}, this.state.job, {
      position: {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      },
    });
    this.setState({
      job,
      marker: {
        position: event.latLng,
        defaultAnimation: 1,
      },
    });
    this.props.onJob(job);
  }

  onAssign(applicator) {
    const job = Object.assign({}, this.state.job, {
      applicator: {
        $ref: "identity",
        $id: applicator._id,
      },
    });
    this.setState({
      job,
    });
    this.props.onJob(job);
  }

  replaceProduct(oldProduct, newProduct) {
    const products = this.state.job.products.slice(),
      index = this.state.job.products.indexOf(oldProduct);

    products[index] = newProduct;

    const job = Object.assign({}, this.state.job, {
      products,
    });
    this.setState({
      job,
    });
    this.props.onJob(job);
  }

  removeProduct(product) {
    const products = this.state.job.products.slice(),
      index = this.state.job.products.indexOf(product);

    if (index > -1) {
      products.splice(index, 1);
    }

    const job = Object.assign({}, this.state.job, {
      products,
    });
    this.setState({
      job,
    });
    this.props.onJob(job);
  }

  onHealthRowUpdate(index, healthRow) {
    const health = this.state.job.health.slice();
    health[index] = healthRow;
    const job = Object.assign({}, this.state.job, {
      health,
    });
    // this.setState({
    //   job
    // });
    this.setJob(job);
    this.props.onJob(job);
  }

  onSiteVisitUpdate(field, event) {
    const job = Object.assign({}, this.state.job, {
      [field]: { quoted: event.target.value },
    });
    // const health = this.state.job.health.slice();
    // health[index] = healthRow;
    // const job = Object.assign({}, this.state.job, {
    //   health
    // });
    this.setState({
      job,
    });
    this.props.onJob(job);
  }

  /**
   * Create a new contact
   */
  createContact() {
    const alts = this.state.job.contact.alts || [];

    alts.push({
      name: "",
      phone: "",
      email: "",
    });

    const job = Object.assign({}, this.state.job, {
      contact: Object.assign({}, this.state.job.contact, {
        alts: alts,
      }),
    });

    this.setJob(job);
    this.props.onJob(job);
  }

  /**
   * Removes a contact at a given position
   * @param {int} contact
   */
  removeContact = (contact) => {
    const contacts = this.getContacts();
    let job = false;

    const alts = [];

    if (contact === 0) {
      // delete the main one, move the alts up.
      contacts.map((c, i) => {
        if (i == 0) {
          return;
        }

        if (c === 1) {
          // set as the main one now
          job = Object.assign({}, this.state.job, {
            contact: Object.assign({}, this.state.job.contact, {
              name: c.name,
              phone: c.phone,
              email: c.email,
            }),
          });

          this.setJob(job);
          this.props.onJob(job);
        } else {
          // add to the list
          alts.push({
            name: c.name,
            phone: c.phone,
            email: c.email,
          });
        }
      });
    } else {
      // delete one of the alts
      contacts.map((c, i) => {
        if (i == 0) {
          return;
        }

        if (i == contact) {
          return;
        }

        alts.push({
          name: c.name,
          phone: c.phone,
          email: c.email,
        });
      });
    }

    job = Object.assign({}, this.state.job, {
      contact: Object.assign({}, this.state.job.contact, {
        alts: alts,
      }),
    });

    this.setJob(job);
    this.props.onJob(job);
  };

  /**
   * The main contact is registered as job.contact.name, job.contact.email with
   * seconday contacts listed as job.contact.alt = [{ name: "", email: ""}]
   */
  getContacts() {
    const job = this.state.job;

    let contacts = [];

    if (job.contact) {
      // root contact
      if (job.contact.name || job.contact.phone || job.contact.email) {
        let root = {
          name: job.contact.name ?? "",
          phone: job.contact.phone ?? "",
          email: job.contact.email ?? "",
        };

        contacts.push(root);
      }

      if (job.contact.alts) {
        contacts = contacts.concat(job.contact.alts);
      }
    }
    return contacts;
  }

  render() {
    const isAdmin = this.props.roles.isOrChild(
      this.props.user.role,
      "country_admin"
    );

    let map = "",
      position,
      marker = this.state.marker;

    if (this.state.job.position && this.state.job.position.latitude) {
      position = this.state.job.position;
      marker = {
        position: {
          lat: position.latitude,
          lng: position.longitude,
        },
        defaultAnimation: 2,
      };
    } else {
      position = {
        latitude: -39.5052721,
        longitude: 176.8767779,
      };
    }

    if (this.props.isScriptLoaded && this.props.isScriptLoadSucceed) {
      map = (
        <div className="map">
          <Map
            marker={marker}
            containerElement={
              <div
                style={{ height: "100%", minHeight: "30vh", width: "100%" }}
                className="map-inner-wrapper"
              />
            }
            mapElement={
              <div
                style={{ height: "100%", minHeight: "30vh", width: "100%" }}
                className="actual-map"
              />
            }
            onMapLoad={(m) =>
              this.setState({
                map: m,
              })
            }
            onMapClick={this.onMapClick.bind(this)}
          />
        </div>
      );
    }

    let siteVisitErrorText = "";
    if (this.state.job.sitevisits && isNaN(+this.state.job.sitevisits.quoted)) {
      siteVisitErrorText = "A number is required";
    }

    let locationCodeErrorText = "";
    if (!this.state.job.locationCode) {
      locationCodeErrorText = "A location is required";
    }

    const siteVisitErrorStyle = {
      color: orange300,
    };

    const locationCodeErrorStyle = {
      color: orange300,
    };

    const contacts = this.getContacts();
    const regions =
      this.props.config.country_code !== "NZL"
        ? ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        : [
            "NORTHLAND",
            "AUCKLAND",
            "WAIKATO",
            "BAY OF PLENTY",
            "HAWKE'S BAY/GISBORNE",
            "MANAWATU/TARANAKI",
            "WELLINGTON",
            "UPPER SOUTH",
            "CHRISTCHURCH",
            "LOWER SOUTH",
          ];

    return (
      <div className="edit-job">
        <div className="general-info">
          <TextField
            placeholder="Job Number"
            value={this.state.job.number}
            onChange={this.onUpdateTextField.bind(this, "number")}
            name="job_number"
          />
          <div className="site-contacts">
            <h4>Site Contacts</h4>
            <div>
              {contacts.map((contact, index) => {
                return (
                  <div key={"contact" + index} className="contact-details">
                    <TextField
                      placeholder="Site Contact Name"
                      value={contact.name}
                      onChange={this.onUpdateContactTextField.bind(
                        this,
                        "job_contact_name_" + index
                      )}
                      name={"job_contact_name" + index}
                    />
                    <TextField
                      placeholder="Site Contact Phone"
                      value={contact.phone}
                      onChange={this.onUpdateContactTextField.bind(
                        this,
                        "job_contact_phone_" + index
                      )}
                      name={"job_contact_phone" + index}
                    />
                    <TextField
                      placeholder="Site Contact Email"
                      value={contact.email}
                      onChange={this.onUpdateContactTextField.bind(
                        this,
                        "job_contact_email_" + index
                      )}
                      name={"job_contact_email_" + index}
                    />
                    {index && (
                      <FlatButton onClick={() => this.removeContact(index)}>
                        X
                      </FlatButton>
                    )}
                  </div>
                );
              })}
            </div>
            <FlatButton onClick={() => this.createContact()}>
              Add contact
            </FlatButton>
          </div>
          <h4>Other Contacts</h4>
          <TextField
            placeholder="Engineer Contact Name"
            value={this.state.job.contact.engineer_name}
            onChange={this.onUpdateContactTextField.bind(this, "engineer_name")}
            name="job_contact_engineer_name"
          />
          <div className="contact-details">
            <TextField
              placeholder="Engineer Contact Phone"
              value={this.state.job.contact.engineer_phone}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "engineer_phone"
              )}
              name="job_contact_engineer_phone"
            />
            <TextField
              placeholder="Engineer Contact Email"
              value={this.state.job.contact.engineer_email}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "engineer_email"
              )}
              name="job_contact_engineer_email"
            />
          </div>
          <TextField
            placeholder="Architect Contact Name"
            value={this.state.job.contact.architect_name}
            onChange={this.onUpdateContactTextField.bind(
              this,
              "architect_name"
            )}
            name="job_contact_architect_name"
          />
          <div className="contact-details">
            <TextField
              placeholder="Architect Contact Phone"
              value={this.state.job.contact.architect_phone}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "architect_phone"
              )}
              name="job_contact_architect_phone"
            />
            <TextField
              placeholder="Architect Contact Email"
              value={this.state.job.contact.architect_email}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "architect_email"
              )}
              name="job_contact_architect_email"
            />
          </div>
          <TextField
            placeholder="Builder Contact Name"
            value={this.state.job.contact.builder_name}
            onChange={this.onUpdateContactTextField.bind(this, "builder_name")}
            name="job_contact_builder_name"
          />
          <div className="contact-details">
            <TextField
              placeholder="Builder Contact Phone"
              value={this.state.job.contact.builder_phone}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "builder_phone"
              )}
              name="job_contact_builder_phone"
            />
            <TextField
              placeholder="Builder Contact Email"
              value={this.state.job.contact.builder_email}
              onChange={this.onUpdateContactTextField.bind(
                this,
                "builder_email"
              )}
              name="job_contact_builder_email"
            />
          </div>
          {this.state.job.products.map((product, index) => (
            <>
              <EditJobProduct
                job={this.state.job}
                product={product}
                key={index}
                onChange={this.replaceProduct.bind(this, product)}
                onRemove={this.removeProduct.bind(this, product)}
              />
            </>
          ))}
          <Button
            onClick={() => {
              const products = this.state.job.products;

              products.push({
                product: "Other",
                area: "",
                charge_rate: "",
              });

              this.setState({
                job: {
                  ...this.state.job,
                  products,
                },
              });
            }}
          >
            Add Product
          </Button>
          <div className="contact-details">
            <TextField
              placeholder="Quoted Site Visits"
              value={this.state.job.sitevisits.quoted}
              onChange={this.onSiteVisitUpdate.bind(this, "sitevisits")}
              name="job_contact_builder_phone"
              errorText={siteVisitErrorText}
              errorStyle={siteVisitErrorStyle}
            />
          </div>
          <div className="contact-details">
            <SelectField
              hintText="Stock Warehouse Location"
              value={this.state.job.locationCode}
              onChange={this.onLocationCode}
              errorText={locationCodeErrorText}
              errorStyle={locationCodeErrorStyle}
            >
              {(this.props.config.country_config.job.locations || [])
                .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
                .map(({ code, name }) => (
                  <MenuItem value={code} primaryText={name} />
                ))}
            </SelectField>
          </div>
          <TextField
            placeholder="Comments"
            value={this.state.job.comments}
            onChange={this.onUpdateTextField.bind(this, "comments")}
            multiLine
            rows={2}
            name="job_comments"
          />
          {isAdmin ? (
            <div className="default-applicator-selection">
              <label>Default Applicator:</label>
              <DropDownMenu
                value={
                  (this.state.job.applicator &&
                    this.state.job.applicator.$id.$oid) ||
                  ""
                }
              >
                <MenuItem
                  value=""
                  primaryText="Select Default Applicator"
                  disabled
                />
                {(this.state.applicators || []).map((applicator, index) => (
                  <MenuItem
                    value={applicator._id.$oid}
                    key={index}
                    primaryText={applicator.name || applicator.username}
                    leftIcon={
                      <SocialPerson
                        style={{
                          fill: applicator.config && applicator.config.color,
                        }}
                      />
                    }
                    onClick={this.onAssign.bind(this, applicator)}
                  />
                ))}
              </DropDownMenu>
            </div>
          ) : (
            ""
          )}
          <h4>Health & Safety</h4>
          {(this.state.job.health || []).map((health, index) => (
            <HealthRow
              onChange={this.onHealthRowUpdate.bind(this, index)}
              health={health}
              key={index}
              disabled={false}
            />
          ))}
        </div>
        <div className="site-info">
          <TextField
            placeholder="Site Name"
            value={this.state.job.site.name}
            onChange={this.onUpdateSiteTextField.bind(this, "name")}
            name="job_site_name"
          />
          <SearchAddressTextField
            isScriptLoaded={this.props.isScriptLoaded}
            isScriptLoadSucceed={this.props.isScriptLoadSucceed}
            placeholder="Site Address 1"
            value={this.state.job.site.address1}
            onChange={this.onUpdateSiteTextField.bind(this, "address1")}
            name="job_site_address1"
            onPlace={this.onPlace.bind(this)}
            position={position}
          />
          <TextField
            placeholder="Site Address 2"
            value={this.state.job.site.address2}
            onChange={this.onUpdateSiteTextField.bind(this, "address2")}
            name="job_site_address2"
          />
          <div className="address-additional">
            <TextField
              placeholder="Site City"
              value={this.state.job.site.city}
              onChange={this.onUpdateSiteTextField.bind(this, "city")}
              name="job_site_city"
            />
            <SelectField
              value={this.state.job.site.region}
              onChange={this.onUpdateSiteTextField.bind(this, "region")}
            >
              {regions.map((region, index) => (
                <MenuItem value={region} key={index} primaryText={region} />
              ))}
            </SelectField>
            <TextField
              placeholder="Site Postcode"
              value={this.state.job.site.postcode}
              onChange={this.onUpdateSiteTextField.bind(this, "postcode")}
              name="job_site_postcode"
            />
          </div>
          {map}
        </div>
      </div>
    );
  }
}

export default loadMapScripts(injectConfig(injectUser(EditJob)));
