import React from 'react';
import PropTypes from 'prop-types';
import Callback from './callback';

class LogoutScreen extends React.Component {

  componentDidMount() {
    return this.props.logout();
  }

  render() {
    return <Callback />
  }

}

LogoutScreen.propTypes = {
  logout: PropTypes.func.isRequired
};

export default LogoutScreen;
