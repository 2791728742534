import PropTypes from "prop-types";
import React from "react";
import MimeTypes from "mime-types";
import ClassNames from "classnames";
import { Popover } from "material-ui";

const types = {
  default: "file-o",

  "application/zip": "file-archive-o",
  "application/x-zoo": "file-archive-o",
  "application/x-stuffitx": "file-archive-o",
  "application/x-stuffit": "file-archive-o",
  "application/x-rar-compressed": "file-archive-o",
  "application/x-lzx": "file-archive-o",
  "application/x-lzh": "file-archive-o",
  "application/x-gtar": "file-archive-o",
  "application/x-gca-compressed": "file-archive-o",
  "application/x-dgc-compressed": "file-archive-o",
  "application/x-dar": "file-archive-o",
  "application/x-cfs-compressed": "file-archive-o",
  "application/x-b1": "file-archive-o",
  "application/x-astrotite-afa": "file-archive-o",
  "application/x-arj": "file-archive-o",
  "application/x-apple-diskimage": "file-archive-o",
  "application/x-alz-compressed": "file-archive-o",
  "application/x-ace-compressed": "file-archive-o",
  "application/x-7z-compressed": "file-archive-o",
  "application/vnd.ms-cab-compressed": "file-archive-o",
  "application/vnd.android.package-archive": "file-archive-o",

  audio: "file-audio-o",

  "application/json": "file-code-o",
  "application/xml": "file-code-o",
  "application/javascript": "file-code-o",
  "text/html": "file-code-o",
  "text/css": "file-code-o",

  "application/vnd.ms-excel": "file-excel-o",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12": "file-excel-o",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "file-excel-o",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    "file-excel-o",
  "application/msexcel": "file-excel-o",
  "application/x-msexcel": "file-excel-o",
  "application/x-ms-excel": "file-excel-o",
  "application/x-excel": "file-excel-o",
  "application/x-dos_ms_excel": "file-excel-o",
  "application/xls": "file-excel-o",
  "application/x-xls": "file-excel-o",

  image: "file-image-o",

  video: "file-movie-o",

  "application/pdf": "file-pdf-o",

  "application/vnd.ms-powerpoint": "file-powerpoint-o",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "file-powerpoint-o",
  "application/vnd.openxmlformats-officedocument.presentationml.template":
    "file-powerpoint-o",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    "file-powerpoint-o",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12": "file-powerpoint-o",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12":
    "file-powerpoint-o",
  "application/vnd.ms-powerpoint.template.macroEnabled.12": "file-powerpoint-o",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12":
    "file-powerpoint-o",

  "text/plain": "file-text-o",
  text: "file-text-o",

  "application/msword": "file-word-o",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "file-word-o",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    "file-word-o",
  "application/vnd.ms-word.document.macroEnabled.12": "file-word-o",
  "application/vnd.ms-word.template.macroEnabled.12": "file-word-o",
};

function getIconClassForMIMEBase(mime, name) {
  const checkMime = MimeTypes.lookup(name) || mime;
  if (typeof checkMime !== "string") {
    return types.default;
  }
  if (types[checkMime]) {
    return types[checkMime];
  }
  const split = checkMime.split("/");
  return types[split[0]] || types.default;
}

export function getIconClassForMIME(mime, name) {
  const icon = getIconClassForMIMEBase(mime, name);
  return `fa fa-${icon}`;
}

export default class FileIcon extends React.PureComponent {
  static propTypes = {
    mime: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPreview: false,
      anchorEl: null,
    };
  }

  render() {
    const iconClass = getIconClassForMIME(this.props.mime, this.props.name);
    const className = ClassNames(iconClass, this.props.className || "");

    if (iconClass.indexOf("file-image-o") > -1 && this.props.href) {
      return (
        <>
          <a className="file-icon-preview" href={this.props.href}>
            <img
              src={this.props.href}
              style={{
                width: "80px",
                height: "80px",
                objectFit: "contain",
                objectPosition: "center top",
              }}
            />
          </a>
        </>
      );
    }
    return <i className={className} />;
  }
}
