import IconButton from "material-ui/IconButton";
import Timeline from "material-ui/svg-icons/action/timeline";
import { OutputModal } from "./modal";
import React, { Fragment, useCallback, useMemo, useState } from "react";

function getBSONString(value) {
  if (typeof value === "string") {
    return value;
  }
  if (value && value.$oid) {
    return value.$oid;
  }
  if (value && value.$date) {
    return value.$date;
  }
  return undefined;
}

function getAssignedTo(child) {
  if (child.assignedTo) {
    return child.assignedTo;
  }
  const state = (child.stateHistory || []).find(
    (state) => state.createdBy || state.updatedBy
  );
  return state ? getBSONString(state.createdBy || state.updatedBy) : undefined;
}

export function DisplayOutputModalButton(props) {
  const {
    applicators: applicatorDocuments,
    visit,
    job,
    iconButtonTooltipStyle,
    iconButtonStyle,
    iconButtonSVGStyle,
  } = props;
  const visitValid = visit.input && visit.children && visit.children.length;
  const [isOpen, setOpen] = useState(false);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const tasks = useMemo(() => {
    if (!visitValid) {
      return undefined;
    }
    const base = {
      _id: getBSONString(visit._id),
      correlationIdentifier: getBSONString(visit._id),
      relatedTo: [getBSONString(job._id)],
      createdAt: getBSONString(visit.created),
      updatedAt: getBSONString(visit.updated || visit.created),
      state: visit.state,
      type: "SiteVisit",
      input: {
        ...visit.input,
        products: visit.input.products.filter(({ plan }) => plan),
      },
      output: visit.output || {
        images: [],
        comments: [],
        variations: [],
        products: [],
        contact: [],
      },
      children: visit.children || [],
    };

    const applicators = base.children
      .map(getAssignedTo)
      .filter((value) => value)
      .filter((value, index, array) => {
        const before = array.slice(0, index);
        return !before.includes(value);
      });

    return applicators
      .map((applicator) => {
        function isCreatedByApplicatorOrPM(value) {
          return (
            !value.createdBy ||
            value.createdBy === applicator ||
            !applicators.includes(value.createdBy)
          );
        }
        return {
          ...base,
          correlationIdentifier:
            typeof base.correlationIdentifier === "string"
              ? base.correlationIdentifier
              : base.correlationIdentifier.$oid,
          assignedTo: applicator,
          assignedToDocument: applicatorDocuments.find(
            (document) => getBSONString(document._id) === applicator
          ),
          children: base.children
            .filter(
              (child) =>
                getAssignedTo(child) === applicator ||
                (child.createdBy && isCreatedByApplicatorOrPM(child)) ||
                !getAssignedTo(child)
            )
            .map((child) => ({
              ...child,
              _id: getBSONString(child._id),
              createdAt: getBSONString(child.createdAt),
              updatedAt: getBSONString(child.updatedAt),
              completedAt: getBSONString(child.completedAt),
              assignedTo: applicator,
            })),
          output: {
            ...base.output,
            images: (base.output.images || []).filter(
              isCreatedByApplicatorOrPM
            ),
            variations: (base.output.variations || []).filter(
              isCreatedByApplicatorOrPM
            ),
            products: (base.output.products || []).filter(
              isCreatedByApplicatorOrPM
            ),
            comments: (base.output.comments || []).filter(
              isCreatedByApplicatorOrPM
            ),
            contact: (base.output.contact || []).filter(
              isCreatedByApplicatorOrPM
            ),
          },
        };
      })
      .filter((task) => task.assignedToDocument);
  }, [visit]);

  if (!visitValid) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <IconButton
        title="Edit Products"
        alt="Edit Products"
        tooltipPosition="bottom-center"
        style={iconButtonStyle}
        iconStyle={Object.assign({}, iconButtonSVGStyle, { fill: "#000" })}
        tooltipStyles={iconButtonTooltipStyle}
        onClick={onOpen}
      >
        <Timeline />
      </IconButton>
      <OutputModal tasks={tasks} onRequestClose={onClose} isOpen={isOpen} />
    </Fragment>
  );
}
