import React from "react";
import LinearProgress from "material-ui/LinearProgress";
import Dialog from "material-ui/Dialog";
import { List, ListItem } from "material-ui/List";
import Avatar from "material-ui/Avatar";
import FileFolder from "material-ui/svg-icons/file/folder";
import PropTypes from "prop-types";

class DialogBox extends React.PureComponent {
  render() {
    return (
      <Dialog
        title="Upload to"
        className="dialog-box"
        actions={this.props.actions}
        modal={false}
        open={this.props.dialogOpen}
        onRequestClose={this.props.handleClose}
      >
        <LinearProgress
          mode="indeterminate"
          className={
            this.props.uploadingFiles
              ? "uploading-files"
              : "not-uploading-files"
          }
        />
        <List>
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="Pictures"
            secondaryText="Please upload all pictures into this directory"
            onClick={this.props.handleUpload.bind(this, "Pictures")}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="Markups"
            secondaryText="Please upload all markups into this directory"
            onClick={this.props.handleUpload.bind(this, "Markups")}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="Health & Safety"
            secondaryText="Please upload all Health & Safety related documents into this directory"
            onClick={this.props.handleUpload.bind(this, "Health & Safety")}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="ITP Forms"
            secondaryText="Please upload all ITP Forms related documents into this directory"
            onClick={this.props.handleUpload.bind(this, "ITP Forms")}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="QA Forms"
            secondaryText="Please upload all QA Forms into this directory"
            onClick={this.props.handleUpload.bind(this, "QA Forms")}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="Contracts, Quote & Other"
            secondaryText="Please upload all Contracts, Quotes and any other documents into this directory"
            onClick={this.props.handleUpload.bind(
              this,
              "Contracts, Quote & Other"
            )}
          />
          <ListItem
            className="folder-list-item"
            leftAvatar={<Avatar icon={<FileFolder />} />}
            primaryText="Payment Claims"
            secondaryText="Please upload all Payment Claims into this directory"
            onClick={this.props.handleUpload.bind(this, "Payment Claims")}
          />
        </List>
      </Dialog>
    );
  }
}

DialogBox.propTypes = {
  className: PropTypes.string,
  showProgress: PropTypes.bool,
  handleUpload: PropTypes.func.isRequired,
  uploadingFiles: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
};

export default DialogBox;
