import TextField from "material-ui/TextField";
import React, {useCallback} from "react";
import {useTraceUpdate} from "./props";

export function EditArea(props) {
  useTraceUpdate(props, "EditArea");
  const { disabled, area, onChange } = props;
  const onAreaChange = useCallback((index, event) => {
    let newArea = area.slice();
    newArea[index] = event.target.value;
    newArea.reverse();
    newArea = newArea.filter(
      (value, index, array) => {
        if (value) {
          return true;
        }
        const before = array.slice(0, index);
        const anyBefore = before.find(value => value);
        return !!anyBefore;
      }
    );
    newArea.reverse();
    onChange(newArea);
  }, [area, onChange]);
  return (
    <div className="edit-area">
      {
        area.concat("").map(
          (value, index) => (
            <TextField
              disabled={disabled}
              value={value}
              onChange={onAreaChange.bind(undefined, index)}
              hintText={`Area/Grid Point`}
              key={index}
              floatingLabelText={
                index === 0 && value ? (
                  "Area/Grid Point"
                ) : undefined
              }
            />
          )
        )
      }
    </div>
  )
}
