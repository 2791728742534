// @ts-nocheck
import React from "react";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";

class EditInvoice extends React.PureComponent {
  getPopulatedVisit(propVisit = this.props.visit) {
    return Object.assign({}, propVisit, {
      invoice: Object.assign({}, propVisit.invoice || {}, {
        number: (propVisit.invoice || {}).number || "",
        contact_builder_employee:
          (propVisit.invoice || {}).contact_builder_employee || "",
        contact_engineer_employee:
          (propVisit.invoice || {}).contact_engineer_employee || "",
        contact_architect_employee:
          (propVisit.invoice || {}).contact_architect_employee || "",
        allocation: (propVisit.invoice || {}).allocation || false,
      }),
    });
  }

  onUpdateField(field, event) {
    const visit = this.getPopulatedVisit(this.props.visit);

    visit.invoice[field] = event.target.value;

    this.props.onChange(visit, this.props.job);
  }

  isLastVisit() {
    if (!this.props.visit) {
      return false;
    }

    const visitsNotComplete = (this.props.visits || [])
      .filter((visit) => {
        return visit.status !== "Complete";
      })
      .filter((visit) => {
        return visit._id.$oid !== this.props.visit._id.$oid;
      });

    return !visitsNotComplete.length;
  }

  isNonDefaultApplicator() {
    if (!(this.props.job && this.props.visit)) {
      return false;
    }

    if (
      !(
        this.props.job.applicator &&
        this.props.job.applicator.$id &&
        this.props.job.applicator.$id.$oid
      )
    ) {
      return true; // Can never be the default
    }

    if (
      !(
        this.props.visit.applicator &&
        this.props.visit.applicator.$id &&
        this.props.visit.applicator.$id.$oid
      )
    ) {
      return true; // No way to tell
    }

    return (
      this.props.visit.applicator.$id.$oid !==
      this.props.job.applicator.$id.$oid
    );
  }

  getSingleContactComponent(type, prefix) {
    const name = this.props.job.contact[`${prefix}name`],
      phone = this.props.job.contact[`${prefix}phone`],
      email = this.props.job.contact[`${prefix}email`];
    //
    // if (!(name && phone && email)) {
    //   return null;
    // }

    return (
      <p>
        {type} Name: {name || "Not Provided"}
        <br />
        {type} Phone: {phone || "Not Provided"}
        <br />
        {type} Email: {email || "Not Provided"}
      </p>
    );
  }

  getContactComponent(visit) {
    const site = this.getSingleContactComponent("Site", ""),
      builder = this.getSingleContactComponent("Builder", "builder_"),
      engineer = this.getSingleContactComponent("Engineer", "engineer_"),
      architect = this.getSingleContactComponent("Architect", "architect_");

    return (
      <p>
        This is the last visit to be completed,
        <br />
        Please provide who has contacted the below:
        <br />
        <TextField
          floatingLabelText="Who contacted the builder?"
          value={visit.invoice.contact_builder_employee}
          onChange={this.onUpdateField.bind(this, "contact_builder_employee")}
        />
        <br />
        <TextField
          floatingLabelText="Who contacted the engineer?"
          value={visit.invoice.contact_engineer_employee}
          onChange={this.onUpdateField.bind(this, "contact_engineer_employee")}
        />
        <br />
        <TextField
          floatingLabelText="Who contacted the architect?"
          value={visit.invoice.contact_architect_employee}
          onChange={this.onUpdateField.bind(this, "contact_architect_employee")}
        />
        <br />
        <br />
        Here are the contact details if required:
        <br />
        <br />
        {site}
        <br />
        {builder}
        <br />
        {engineer}
        <br />
        {architect}
        <br />
      </p>
    );
  }

  render() {
    const visit = this.getPopulatedVisit(this.props.visit),
      isLast = this.isLastVisit(),
      nonDefaultApplicator = this.isNonDefaultApplicator();

    return (
      <div className="visit-edit-area">
        <TextField
          floatingLabelText="Invoice Number"
          value={visit.invoice.number}
          onChange={this.onUpdateField.bind(this, "number")}
        />
        {nonDefaultApplicator ? (
          <p>
            Please ensure the stock for this site visit was allocated to the
            correct applicator
            <br />
            <br />
            <Checkbox
              checked={visit.invoice.allocation || false}
              label="Allocated"
              onCheck={(event, checked) => {
                this.onUpdateField("allocation", {
                  target: {
                    value: checked,
                  },
                });
              }}
            />
            <br />
          </p>
        ) : (
          ""
        )}
        {isLast ||
        visit.invoice.contact_architect_employee ||
        visit.invoice.contact_engineer_employee ||
        visit.invoice.contact_builder_employee
          ? this.getContactComponent(visit)
          : ""}
      </div>
    );
  }
}

EditInvoice.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  visit: React.PropTypes.object,
  job: React.PropTypes.object,
  visits: React.PropTypes.array,
};

export default EditInvoice;
