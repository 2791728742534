import React from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { orange300 } from "material-ui/styles/colors";
import { injectUser } from "../../../services/user";
import { injectConfig } from "../../../services/config";
import DefaultProducts from "../../settings/components/default-products";

class EditJobProduct extends React.PureComponent {
  onProductApplied(_, __, value) {
    this.onChangeValue("product", value);
  }

  onProductOther(event) {
    this.onChangeValue("productOther", event.target.value);
  }

  onArea(event) {
    this.onChangeValue("area", event.target.value);
  }

  onChargeRate(event) {
    this.onChangeValue("charge_rate", event.target.value);
  }

  onChangeValue(field, value) {
    this.props.onChange(
      Object.assign({}, this.props.product, {
        [field]: value,
      })
    );
  }

  render() {
    const isAdminOrAccounts = this.props.roles.isOrChild(
      this.props.user.role,
      "accounts"
    );

    const areaErrorStyle = {
        color: orange300,
      },
      productErrorStyle = {
        color: orange300,
      },
      productOtherErrorStyle = {
        color: orange300,
      },
      chargeRateErrorStyle = {
        color: orange300,
      };
    let areaErrorText = "",
      productErrorText = "",
      productOtherErrorText = "",
      chargeRateErrorText = "";

    if (this.props.product.area && isNaN(+this.props.product.area)) {
      areaErrorText = "A number is required";
    }

    if (
      this.props.product.charge_rate &&
      isNaN(+this.props.product.charge_rate)
    ) {
      chargeRateErrorText = "A number is required";
    }

    if (
      (this.props.product.area || this.props.product.charge_rate) &&
      !this.props.product.product
    ) {
      productErrorText = "A product is required";
    }

    if (
      this.props.product.product === "Other" &&
      !this.props.product.productOther
    ) {
      productOtherErrorText = "A product description is required";
    }

    return (
      <div className="edit-job-product">
        <SelectField
          hintText="Product to be applied"
          value={this.props.product.product}
          onChange={this.onProductApplied.bind(this)}
          errorText={productErrorText}
          errorStyle={productErrorStyle}
        >
          {(this.props.config.country_config.job.products || DefaultProducts)
            .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
            .map(({ code, name }) => (
              <MenuItem key={code} value={code} primaryText={name} />
            ))}
          <MenuItem value="Other" primaryText="Other" />
        </SelectField>
        {this.props.product.product === "Other" ? (
          <TextField
            value={this.props.product.productOther}
            onChange={this.onProductOther.bind(this)}
            hintText="Product Description"
            errorText={productOtherErrorText}
            errorStyle={productOtherErrorStyle}
          />
        ) : (
          ""
        )}
        <TextField
          className="text-field-flex"
          value={this.props.product.area}
          onChange={this.onArea.bind(this)}
          hintText={
            <span>
              Number, m<sup>2</sup> to be applied
            </span>
          }
          errorText={areaErrorText}
          errorStyle={areaErrorStyle}
        />
        {isAdminOrAccounts ? (
          <TextField
            className="text-field-flex"
            value={this.props.product.charge_rate}
            onChange={this.onChargeRate.bind(this)}
            hintText={
              <span>
                Number, charge rate in $/m<sup>2</sup>
              </span>
            }
            errorText={chargeRateErrorText}
            errorStyle={chargeRateErrorStyle}
          />
        ) : null}
        <FlatButton onClick={this.props.onRemove}>Clear</FlatButton>
      </div>
    );
  }
}

EditJobProduct.propTypes = {
  product: React.PropTypes.shape({
    product: React.PropTypes.string,
    productOther: React.PropTypes.string,
    area: React.PropTypes.string,
  }),
  onChange: React.PropTypes.func.isRequired,
  onRemove: React.PropTypes.func.isRequired,
  user: React.PropTypes.object,
  roles: React.PropTypes.object,
};

export default injectConfig(injectUser(EditJobProduct));
