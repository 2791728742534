export default [
  {
    code: "A1000",
    name: "A1000",
    infusionProductCode: "",
    infusionResourceCode: "L1000",
  },
  {
    code: "A1000H",
    name: "A1000H",
    infusionProductCode: "",
    infusionResourceCode: "L1000",
  },
  {
    code: "A1000W",
    name: "A1000W",
    infusionProductCode: "A1000",
    infusionResourceCode: "LA1000WR",
  },
  {
    code: "A1200",
    name: "A1200",
    infusionProductCode: "",
    infusionResourceCode: "L1200",
  },
  {
    code: "A2000DEFL",
    name: "A2000DEFL",
    infusionProductCode: "",
    infusionResourceCode: "LDEFL",
  },
  {
    code: "A2000",
    name: "A2000",
    infusionProductCode: "",
    infusionResourceCode: "L2000",
  },
  {
    code: "A2000 MEDI",
    name: "A2000 MEDI",
    infusionProductCode: "",
    infusionResourceCode: "L2000M",
  },
  {
    code: "A300",
    name: "A300",
    infusionProductCode: "",
    infusionResourceCode: "L300",
  },
  {
    code: "A3000",
    name: "A3000",
    infusionProductCode: "",
    infusionResourceCode: "L3000",
  },
  {
    code: "A631",
    name: "A631",
    infusionProductCode: "",
    infusionResourceCode: "L631",
  },
  {
    code: "A7000",
    name: "A7000",
    infusionProductCode: "",
    infusionResourceCode: "L7000",
  },
  {
    code: "CPM",
    name: "Cretepolish Max",
    infusionProductCode: "",
    infusionResourceCode: "LCMP",
  },
  {
    code: "AGS",
    name: "Gloss Polish",
    infusionProductCode: "",
    infusionResourceCode: "LGSG",
  },
  {
    code: "AGSB",
    name: "Base Gloss Polish",
    infusionProductCode: "AGS",
    infusionResourceCode: "LAQG",
  },
  {
    code: "HLS",
    name: "Harden It",
    infusionProductCode: "",
    infusionResourceCode: "LHLS",
  },
  {
    code: "MS",
    name: "Masterseal",
    infusionProductCode: "",
    infusionResourceCode: "",
  },
  {
    code: "Scrubbing",
    name: "Scrubbing",
    infusionProductCode: "LSB",
    infusionResourceCode: "NA",
  },
  {
    code: "Masterseal",
    name: "Masterseal (Pre-Infusion)",
    infusionProductCode: "",
    infusionResourceCode: "LMS",
  },
  {
    code: "Cretepolish Max",
    name: "Cretepolish Max (Pre-Infusion)",
    infusionProductCode: "",
    infusionResourceCode: "",
  },
  {
    code: "Gloss Polish",
    name: "Gloss Polish (Pre-Infusion)",
    infusionProductCode: "",
    infusionResourceCode: "",
  },
  {
    code: "IG200",
    name: "IG200",
    infusionProductCode: "",
    infusionResourceCode: "",
  },
];
